//@ts-nocheck

import { useQuery } from 'react-query'
import './ComboStars.scss'
import { getActive } from '../../api/comboStars'

export default function ComboStars() {

    const { data, isLoading, isError, isSuccess, refetch } = useQuery(
        ['comboStars'],
        () => getActive(),
        {
            keepPreviousData: true,
        }
    )

    return (
        <>
            {isSuccess &&
                <>
                    {data.comboStars !== null &&
                        <>
                            <div className={'h-100 d-flex align-items-center'} style={{marginTop: '8px'}}>
                                <div className='combo-stars d-flex'>
                                    <div className='combo-stars-item d-flex align-items-center' data-bs-toggle="modal" data-bs-target="#comboStars">
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.9257 17.9001C20.2855 17.0636 20.5 16.0616 20.5 14.8667C20.5 11.4725 18.4971 9.01097 16.601 7.54113C15.6282 6.78704 14.3823 7.56917 14.3823 8.79998C14.3823 9.63552 14.007 11.0269 13.2466 12.0514C12.4158 13.1708 11.343 12.0527 11.2679 10.6607C11.2336 10.0245 10.5735 9.7992 10.0427 10.1517C8.8818 10.9226 7.5 12.4541 7.5 14.8667C7.5 20.4133 11.5444 21.8 13.5667 21.8C14.6162 21.8 16.1424 21.514 17.4958 20.6824" stroke="#171027" stroke-linecap="round" />
                                            <path d="M27 14C27 20.1282 27 23.1924 25.0961 25.0961C23.1924 27 20.1282 27 14 27C7.87173 27 4.80761 27 2.90381 25.0961C1 23.1924 1 20.1282 1 14C1 7.87173 1 4.80761 2.90381 2.90381C4.80761 1 7.87173 1 14 1C20.1282 1 23.1924 1 25.0961 2.90381C26.3621 4.16967 26.7863 5.94851 26.9284 8.8" stroke="#171027" stroke-linecap="round" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </>
    )
}
