//@ts-nocheck

import { useTranslation } from 'react-i18next'
import { getInitData } from '../../utils/telegram'
import './UserInfoOffcanvas.scss'
import { getInfo } from '../../api/user'
import { useQuery } from 'react-query'
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../DataFetchError/DataFetchError'
import { numberFormat } from '../../utils/NumberFormat'

export default function UserInfoOffcanvas({ index, userId }) {

    const { t, i18n } = useTranslation()

    const { data, isLoading, isError, isSuccess, isFetching } = useQuery(
        ['userInfo'+userId],
        () => getInfo(userId),
        {
            keepPreviousData: true,
        }
    )

    return (
        <div className={'offcanvas offcanvas-bottom hero-user-info-offcanvas-main-container m-0 d-flex flex-column justify-content-end bg-transparent'} tabIndex="-1" id={"offcanvasBottomHero" + index} aria-labelledby="offcanvasBottomLabel">
            <div className='h-auto'>
                <div className={'user-info-offcanvas_cont position-relative h-auto'}>

                    <img width={'100%'} height={'auto'} src={`/img/heroes/background/defaultuser.png`} style={{ zIndex: 1, opacity: '50%' }} />

                    <div className='hero-user-info-offcanvas-padding w-100 h-100 position-absolute top-50 start-50 translate-middle' style={{ zIndex: 2 }}>
                        <div className="w-100">
                            <svg className='position-absolute top-0 end-0' width="15" height="15" viewBox="0 0 12 12"
                                fill="none" xmlns="http://www.w3.org/2000/svg" data-bs-dismiss="offcanvas"
                                aria-label="Закрыть" style={{ cursor: 'pointer', zIndex: 2, marginTop: '5px', marginRight: '5px' }}>
                                <path d="M1.03613 1.00055L11.0361 11" stroke="#B0B0B0" strokeLinecap="round"
                                    strokeLinejoin="round" />
                                <path d="M1 10.9987L10.9991 1" stroke="#B0B0B0" strokeLinecap="round"
                                    strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className="h-100 m-0 p-0 d-flex flex-column justify-content-between">

                            {(isSuccess && !isFetching) &&
                                <div>
                                    <div className={'d-flex position-relative m-auto gap-3 pb-3'}>
                                        <div className={'position-relative'}>
                                            {data.info.user.photoUrl !== null &&
                                                <img style={{ borderRadius: '4px' }} src={data.info.user.photoUrl} className={'user-info-offcanvas_img'} />
                                            }
                                            {data.info.user.photoUrl === null &&
                                                <img style={{ borderRadius: '4px' }} src={'/img/heroes/user.png'} className={'user-info-offcanvas_img'} />
                                            }
                                        </div>
                                        <div className={''}>
                                            <div className='hero-user-info-offcanvas-name'>
                                                {(data.info.user.lastName === "" ? data.info.user.firstName : data.info.user.firstName + " " + data.info.user.lastName).substring(0, 15) + ((data.info.user.lastName === "" ? data.info.user.firstName : data.info.user.firstName + data.info.user.lastName).length > 15 ? "..." : "")}
                                            </div>

                                            <div className={'hero-user-info-offcanvas-country w-100'}>{t("userInfoOffcanvas.country")} <span className='hero-user-info-offcanvas-country-value'>{data.info.user.country}</span></div>
                                            <div className={'hero-user-info-offcanvas-clan w-100'}>{t("userInfoOffcanvas.clan")} <span className='hero-user-info-offcanvas-clan-value'>{data.info.clan.name}</span></div>

                                            <div className={'hero-user-info-offcanvas-dps w-100'}>{t("userInfoOffcanvas.dps")} <span className='hero-user-info-offcanvas-dps-value'>{numberFormat(data.info.damage.DPS)}</span></div>
                                            <div className={'hero-user-info-offcanvas-ips w-100'}>{t("userInfoOffcanvas.ips")} <span className='hero-user-info-offcanvas-ips-value'>{numberFormat(data.info.damage.IPS)}</span></div>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column'>
                                        <div className='hero-user-info-offcanvas-user-top-title position-absolute'>{t("userInfoOffcanvas.TopAchievementsTitle")}</div>
                                        <div className='hero-user-info-offcanvas-abilities-user d-flex justify-content-center'>
                                            <svg style={{ marginRight: '18px' }} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9.6398V1H9.6398M9.6398 41H1V32.3602M32.3612 41H41V32.3602M32.3612 1H41V9.6398" stroke="#B28E4C" strokeOpacity="0.2" strokeMiterlimit="20" strokeLinecap="round" />
                                                <path d="M19.7283 26.8064C20.7157 26.8064 21.5162 26.0474 21.5162 25.1112C21.5162 24.175 20.7157 23.416 19.7283 23.416C18.7409 23.416 17.9404 24.175 17.9404 25.1112C17.9404 26.0474 18.7409 26.8064 19.7283 26.8064Z" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M19.7275 25.1113V28.5015" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M34.0322 15.1765V11.04" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M34.0317 15.1768L31.3975 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M30.2178 12.9053L34.0321 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M34.0322 15.1768L36.6664 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M37.8466 12.9053L34.0322 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M30.4558 22.9208V32.7394C30.4558 33.9917 29.3926 34.9998 28.0718 34.9998H11.3841C10.0632 34.9998 9 33.9917 9 32.7394V20.3073C9 19.055 10.0632 18.0469 11.3841 18.0469H25.3154" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.5986 11.7812C12.5986 8.03611 15.8007 5 19.7506 5C23.7004 5 26.9025 8.03611 26.9025 11.7812" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M26.9023 11.7812V14.0411" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.5986 11.2158V14.0405" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <svg style={{ marginRight: '18px' }} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9.6398V1H9.6398M9.6398 41H1V32.3602M32.3612 41H41V32.3602M32.3612 1H41V9.6398" stroke="#B28E4C" strokeOpacity="0.2" strokeMiterlimit="20" strokeLinecap="round" />
                                                <path d="M19.7283 26.8064C20.7157 26.8064 21.5162 26.0474 21.5162 25.1112C21.5162 24.175 20.7157 23.416 19.7283 23.416C18.7409 23.416 17.9404 24.175 17.9404 25.1112C17.9404 26.0474 18.7409 26.8064 19.7283 26.8064Z" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M19.7275 25.1113V28.5015" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M34.0322 15.1765V11.04" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M34.0317 15.1768L31.3975 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M30.2178 12.9053L34.0321 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M34.0322 15.1768L36.6664 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M37.8466 12.9053L34.0322 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M30.4558 22.9208V32.7394C30.4558 33.9917 29.3926 34.9998 28.0718 34.9998H11.3841C10.0632 34.9998 9 33.9917 9 32.7394V20.3073C9 19.055 10.0632 18.0469 11.3841 18.0469H25.3154" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.5986 11.7812C12.5986 8.03611 15.8007 5 19.7506 5C23.7004 5 26.9025 8.03611 26.9025 11.7812" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M26.9023 11.7812V14.0411" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.5986 11.2158V14.0405" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <svg style={{ marginRight: '18px' }} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9.6398V1H9.6398M9.6398 41H1V32.3602M32.3612 41H41V32.3602M32.3612 1H41V9.6398" stroke="#B28E4C" strokeOpacity="0.2" strokeMiterlimit="20" strokeLinecap="round" />
                                                <path d="M19.7283 26.8064C20.7157 26.8064 21.5162 26.0474 21.5162 25.1112C21.5162 24.175 20.7157 23.416 19.7283 23.416C18.7409 23.416 17.9404 24.175 17.9404 25.1112C17.9404 26.0474 18.7409 26.8064 19.7283 26.8064Z" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M19.7275 25.1113V28.5015" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M34.0322 15.1765V11.04" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M34.0317 15.1768L31.3975 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M30.2178 12.9053L34.0321 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M34.0322 15.1768L36.6664 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M37.8466 12.9053L34.0322 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M30.4558 22.9208V32.7394C30.4558 33.9917 29.3926 34.9998 28.0718 34.9998H11.3841C10.0632 34.9998 9 33.9917 9 32.7394V20.3073C9 19.055 10.0632 18.0469 11.3841 18.0469H25.3154" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.5986 11.7812C12.5986 8.03611 15.8007 5 19.7506 5C23.7004 5 26.9025 8.03611 26.9025 11.7812" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M26.9023 11.7812V14.0411" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.5986 11.2158V14.0405" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9.6398V1H9.6398M9.6398 41H1V32.3602M32.3612 41H41V32.3602M32.3612 1H41V9.6398" stroke="#B28E4C" strokeOpacity="0.2" strokeMiterlimit="20" strokeLinecap="round" />
                                                <path d="M19.7283 26.8064C20.7157 26.8064 21.5162 26.0474 21.5162 25.1112C21.5162 24.175 20.7157 23.416 19.7283 23.416C18.7409 23.416 17.9404 24.175 17.9404 25.1112C17.9404 26.0474 18.7409 26.8064 19.7283 26.8064Z" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M19.7275 25.1113V28.5015" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M34.0322 15.1765V11.04" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M34.0317 15.1768L31.3975 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M30.2178 12.9053L34.0321 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M34.0322 15.1768L36.6664 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M37.8466 12.9053L34.0322 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M30.4558 22.9208V32.7394C30.4558 33.9917 29.3926 34.9998 28.0718 34.9998H11.3841C10.0632 34.9998 9 33.9917 9 32.7394V20.3073C9 19.055 10.0632 18.0469 11.3841 18.0469H25.3154" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.5986 11.7812C12.5986 8.03611 15.8007 5 19.7506 5C23.7004 5 26.9025 8.03611 26.9025 11.7812" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M26.9023 11.7812V14.0411" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.5986 11.2158V14.0405" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column'>
                                        <div className='hero-user-info-offcanvas-user-top-title position-absolute'>{t("userInfoOffcanvas.TopHeroesTitle")}</div>
                                        <div className='user-info-offcanvas_indicators_box-user d-flex justify-content-between align-items-center'>

                                            {data.info.topHeroes.top2 !== null &&
                                                <div className='d-flex flex-column'>
                                                    <div className='hero-user-info-offcanvas-user-top d-flex'>
                                                        <img src={`/img/heroes/${data.info.topHeroes.top2.pathImage}.png`} height='94px' width='94px' />
                                                    </div>
                                                    <div className='hero-user-info-offcanvas-user-ipc text-center'>{numberFormat(data.info.topHeroes.top2.damageDPS)}</div>
                                                </div>
                                            }
                                            {data.info.topHeroes.top1 !== null &&
                                                <div className='d-flex flex-column'>
                                                    <div className='hero-user-info-offcanvas-user-top-border d-flex'>
                                                        <img src={`/img/heroes/${data.info.topHeroes.top1.pathImage}.png`} height='100px' width='100px' />
                                                    </div>
                                                    <div className='hero-user-info-offcanvas-user-ipc-top text-center'>{numberFormat(data.info.topHeroes.top1.damageDPS)}</div>
                                                </div>
                                            }
                                            {data.info.topHeroes.top3 !== null &&
                                                <div className='d-flex flex-column'>
                                                    <div className='hero-user-info-offcanvas-user-top d-flex'>
                                                        <img src={`/img/heroes/${data.info.topHeroes.top3.pathImage}.png`} height='94x' width='94px' />
                                                    </div>
                                                    <div className='hero-user-info-offcanvas-user-ipc text-center'>{numberFormat(data.info.topHeroes.top3.damageDPS)}</div>
                                                </div>
                                            }

                                        </div>
                                    </div>

                                </div>
                            }
                            {(isLoading && !isFetching) &&
                                <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
                            }
                            {isFetching &&
                                <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
                            }
                            {isError &&
                                <DataFetchError text={t("questQuests.dataFetchError")} />
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}