//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './MailContainerAllMail.scss'
import { useQuery } from 'react-query';
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader';
import DataFetchError from '../../DataFetchError/DataFetchError';
import { getByUserId } from '../../../api/userMessage';
import MailContainerAllMailNew from './MailContainerAllMailNew/MailContainerAllMailNew';
import MailContainerAllMailReadIt from './MailContainerAllMailReadIt/MailContainerAllMailReadIt';

export default function MailContainerAllMail() {

    const { data, isLoading, isError, isSuccess, refetch } = useQuery(
        ['userMessage'],
        () => getByUserId(),
        {
            keepPreviousData: true,
        }
    )

    const { t } = useTranslation()

    return (

        <div className='h-100 w-100 d-flex flex-column align-items-center'>
            {isSuccess &&
                <>
                    {data.userMessage.map((item, index) => {
                        if (item.status === "NEW") {
                            return (
                                <MailContainerAllMailNew item={item} />
                            )
                        }
                        if (item.status === "READIT") {
                            return (
                                <MailContainerAllMailReadIt item={item} />
                            )
                        }
                    })
                    }
                </>
            }

            {isLoading &&
                <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
            }

            {isError &&
                <DataFetchError text={t("mailContainer.dataFetchError")} />
            }

        </div>
    )
}