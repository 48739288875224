//@ts-nocheck

import './Event.scss'
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";

export default function Event() {

    const { t } = useTranslation()

    return (
        <div>
            <NavLink className={'event-link h-100 d-flex align-items-center'} to="/event">
                <div className='event d-flex'>
                    <div className='event-item d-flex align-items-center'>
                        <svg className="animated-svg-1" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.0001 20.6V24.8" stroke="#171027" stroke-linecap="round"/>
                            <path d="M19.9 29H10.1L10.5749 26.6255C10.7058 25.9711 11.2803 25.5 11.9477 25.5H18.0523C18.7196 25.5 19.2942 25.9711 19.4251 26.6255L19.9 29Z" stroke="#171027" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M24.8 5.19995L26.128 5.64265C27.5141 6.10467 28.2071 6.33569 28.6035 6.88567C29 7.43564 29 8.16618 28.9998 9.62723V9.72877C28.9998 10.9338 28.9998 11.5363 28.7097 12.0293C28.4197 12.5222 27.893 12.8148 26.8396 13.4L22.7 15.7" stroke="#171027"/>
                            <path d="M5.19992 5.19995L3.87185 5.64265C2.48577 6.10467 1.79272 6.33569 1.39634 6.88567C0.999945 7.43564 0.999959 8.16618 1 9.62723V9.72877C1.00004 10.9338 1.00006 11.5363 1.29012 12.0293C1.58018 12.5222 2.10688 12.8148 3.16027 13.4L7.29992 15.7" stroke="#171027"/>
                            <path d="M13.8043 6.63147C14.3363 5.67713 14.6023 5.19995 15 5.19995C15.3978 5.19995 15.6638 5.67713 16.1958 6.63147L16.3334 6.87837C16.4845 7.14957 16.5601 7.28517 16.678 7.37463C16.7958 7.4641 16.9426 7.49731 17.2361 7.56373L17.5034 7.62421C18.5365 7.85795 19.0531 7.97482 19.176 8.37C19.2989 8.76518 18.9467 9.17696 18.2425 10.0005L18.0602 10.2136C17.8601 10.4476 17.76 10.5646 17.7149 10.7094C17.67 10.8542 17.6851 11.0103 17.7154 11.3225L17.7429 11.6068C17.8493 12.7056 17.9027 13.255 17.581 13.4993C17.2591 13.7435 16.7755 13.5209 15.8083 13.0755L15.5581 12.9603C15.2831 12.8337 15.1458 12.7705 15 12.7705C14.8543 12.7705 14.717 12.8337 14.442 12.9603L14.1918 13.0755C13.2246 13.5209 12.741 13.7435 12.4191 13.4993C12.0975 13.255 12.1507 12.7056 12.2572 11.6068L12.2847 11.3225C12.315 11.0103 12.3301 10.8542 12.2852 10.7094C12.2401 10.5646 12.14 10.4476 11.9399 10.2136L11.7577 10.0005C11.0534 9.17696 10.7013 8.76518 10.8242 8.37C10.9471 7.97482 11.4636 7.85795 12.4967 7.62421L12.764 7.56373C13.0575 7.49731 13.2043 7.4641 13.3221 7.37463C13.44 7.28517 13.5156 7.14957 13.6667 6.87837L13.8043 6.63147Z" stroke="#171027"/>
                            <path d="M23.4001 29H6.6001" stroke="#171027" stroke-linecap="round"/>
                            <path d="M22.0001 1.63836C23.0382 1.83646 23.6536 2.05419 24.1853 2.70916C24.8514 3.52942 24.8162 4.41598 24.7457 6.18906C24.4928 12.5633 23.0642 20.6 15.0003 20.6C6.93634 20.6 5.50781 12.5633 5.25474 6.18906C5.18435 4.41598 5.14916 3.52942 5.81517 2.70916C6.48116 1.88892 7.27828 1.75442 8.8725 1.48544C10.4462 1.21993 12.5033 1 15.0003 1C16.0058 1 16.94 1.03567 17.8001 1.09561" stroke="#171027" stroke-linecap="round"/>
                        </svg>
                    </div>
                </div>
            </NavLink>
      </div>
    )
}