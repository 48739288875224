//@ts-nocheck
import './Clan.scss'

import { useMutation, useQuery, useQueryClient } from "react-query"
import { get } from "../../api/clan"
import { useEffect, useState } from "react";
import ClanContainer from '../../components/ClanContainer/ClanContainer';

export default function Clan() {

    return (
        <main className='w-100 d-flex flex-column align-items-center main-background main-container'>
            <ClanContainer />
        </main>
    )
}