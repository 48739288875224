//@ts-nocheck

import './ShopEnergyBottles.scss'
import { useTranslation } from 'react-i18next'
import { get } from '../../api/shopEnergyBottlesStars'
import { useQuery } from 'react-query'
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../DataFetchError/DataFetchError'
import ShopEnergyBottlesItem from './ShopEnergyBottlesItem/ShopEnergyBottlesItem'

function ShopEnergyBottles() {

  const { t, i18n } = useTranslation()
  const { data, isLoading, isError, isSuccess } = useQuery(
    ['shopEnergyBottles'],
    () => get(),
    {
      keepPreviousData: true,
      onSuccess(data) {
        console.log(data);
      },
    }
  )

  return (
    <>
      {isSuccess &&
        <div className='shop-energy-bottles w-100 d-flex flex-wrap overflow-auto' style={{ columnGap: '2%', rowGap: '8px' }}>
          {(data.shopEnergyBottles.map((item, index) => {
            return (
              <ShopEnergyBottlesItem
                key={index}
                item={item}
              />
            )
          })
          )}
        </div>
      }
      {isLoading &&
        <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
      }

      {isError &&
        <DataFetchError text={t("questQuests.dataFetchError")} />
      }
    </>
  )
}

export default ShopEnergyBottles