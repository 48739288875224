//@ts-nocheck

import './RitualStone.scss'
import { useTranslation } from 'react-i18next';
import RitualStoneContainer from '../../components/RitualStoneContainer/RitualStoneContainer';

function RitualStone() {

    const { t } = useTranslation()

    return (
        <div className={'w-100 h-100 d-flex flex-column align-items-center ritual-stone-background main-container'}>
            <RitualStoneContainer />
        </div>

    )
}

export default RitualStone