//@ts-nocheck
import './ModalChestAfterOpen.scss'

import { useTranslation } from "react-i18next";

export default function ModalChestAfterOpen({ curPrize, chestId, curIndex }) {
    const { t } = useTranslation()
    
    return (
        <>
            <div id={'prize-open' + chestId} className='modal fade' tabIndex="-1"
                aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }}>
                <div className="modal-dialog modal-dialog-centered d-flex justify-content-center align-items-center">
                    <div className='modal-content open-chest-modal-cont'>
                        <div className='open-chest-modal-text text-white w-100 d-flex justify-content-start'>
                            {t("chestModalAfterOpen.text")}
                        </div>
                        <div className='open-chest-modal-prizes d-flex flex-wrap justify-content-center'>
                            {curPrize && curPrize.map((prize, index) => {
                                if (index === curIndex - 1) {

                                    return (<div className='chest-open-offcanvas-hero position-relative'>
                                        <img src={`img/heroes/${prize.hero.pathImage}.png`} height='55.5px' width='55.5px' />
                                        <div className='chest-info-modal-hero-name text-white d-flex justify-content-center align-items-center position-absolute'>
                                            {prize.typeReward === 'FRAGMENT' ? prize.count : 'full'}
                                        </div>
                                    </div>)
                                }
                            })}
                        </div>
                        <div className='open-chest-modal-btn position-relative d-flex justify-content-center' type='button' aria-label='Close' data-bs-dismiss="modal">
                            <div>
                                <svg width="137" height="42" viewBox="0 0 137 42" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M135.684 20.8525L116.678 40.7017H30.4526L8.63486 20.8525L30.4526 1H116.678L135.684 20.8525Z"
                                        fill="#171027" fillOpacity="0.3" stroke="#B28E4C" strokeMiterlimit="10" />
                                    <path
                                        d="M129.049 21.1508L110.044 41H23.8177L2 21.1508L23.8177 1.29822H110.044L129.049 21.1508Z"
                                        stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10" />
                                </svg>
                            </div>
                            <div className='open-chest-modal-btn-text w-100 h-100 position-absolute d-flex justify-content-center align-items-center text-white'>
                                {t("chestModal.back")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}