//@ts-nocheck

import './MailContainer.scss'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../DataFetchError/DataFetchError'
import { useTranslation } from 'react-i18next'
import AllMail from './MailContainerAllMail/MailContainerAllMail'
import InboxMail from './MailContainerInboxMail/MailContainerInboxMail'
import SentMail from './MailContainerSentMail/MailContainerSentMail'
import NotificationsMail from './MailContainerNotificationsMail/MailContainerNotificationsMail'
import ToastInfo from '../Toasts/ToastInfo/ToastInfo'

export default function MailContainer() {

    const { t } = useTranslation()

    const [activeNavbarItem, setActiveNavbarItem] = useState('')

    useEffect(() => {
        setActiveNavbarItem('allMail')
    }, [])

    return (
        <div className='mail-container w-100 h-100 d-flex flex-column'>

            <div className='mail-container-navbar d-flex justify-content-between'>
                <div onClick={() => setActiveNavbarItem('allMail')} className={`mail-container-navbar-item ${activeNavbarItem === 'allMail' ? 'mail-container-navbar-item-active' : ''} d-flex align-items-center`}>
                    {t("mailContainer.allMail")}
                </div>

                <div onClick={() => ToastInfo(t("toast.toastInfo"), true)} className={`mail-container-navbar-item ${activeNavbarItem === 'inboxMail' ? 'mail-container-navbar-item-active' : ''} d-flex align-items-center`}>
                    {t("mailContainer.inboxMail")}
                </div>

                <div onClick={() => ToastInfo(t("toast.toastInfo"), true)} className={`mail-container-navbar-item ${activeNavbarItem === 'sentMail' ? 'mail-container-navbar-item-active' : ''} d-flex align-items-center`}>
                    {t("mailContainer.sentMail")}
                </div>

                <div onClick={() => ToastInfo(t("toast.toastInfo"), true)} className={`mail-container-navbar-item ${activeNavbarItem === 'notificationsMail' ? 'mail-container-navbar-item-active' : ''} d-flex align-items-center`}>
                    {t("mailContainer.notificationsMail")}
                </div>
            </div>

            <div className='mail-container-items-container overflow-auto h-100'>
                {activeNavbarItem === 'allMail' &&
                    <AllMail />
                }
            </div>

        </div>
    )

}