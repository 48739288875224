//@ts-nocheck

import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function get(name: string) {
    const { data } = await axios.post(
        apiURL + '/api/v1/clan',
        {
            name: name,
        },
        axiosConfig
    )

    return data
}

export async function getById(clanId: number) {
    const { data } = await axios.post(
        apiURL + '/api/v1/clan/by-id',
        {
            clanId: Number(clanId),
        },
        axiosConfig
    )

    return data
}

export async function getUserById() {
    const { data } = await axios.post(
        apiURL + '/api/v1/clan/by-user-id',
        {},
        axiosConfig
    )

    return data
}

export async function exitByUserId() {
    const { data } = await axios.post(
        apiURL + '/api/v1/clan/exit-by-user-id',
        {},
        axiosConfig
    )

    return data
}

export async function joinByUserId(clanId: number) {
    const { data } = await axios.post(
        apiURL + '/api/v1/clan/join-by-user-id',
        {
            clanId: Number(clanId),
        },
        axiosConfig
    )

    return data
}

export async function kickByUserId(userId: number) {
    const { data } = await axios.post(
        apiURL + '/api/v1/clan/kick-by-user-id',
        {
            userId: Number(userId),
        },
        axiosConfig
    )

    return data
}

export async function create(obj: Object) {
    const { data } = await axios.post(
        apiURL + '/api/v1/clan/create',
        {
            name: obj.name,
            description: obj.description
        },
        axiosConfig
    )

    return data
}