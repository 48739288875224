//@ts-nocheck

import { useSelector } from 'react-redux'
import './BattlesOldCoinBalance.scss'

export default function BattlesOldCoinBalance() {

  const userOldCoinCount = useSelector((state) => state.userOldCoin.count)

  return (
    <div className='battles-old-coin-balance w-100 text-white d-flex justify-content-center align-items-center'>
      <img src='/img/loot/old-coin.png' width={21} height={24} />
      <div className='battles-old-coin-balance-count'>{userOldCoinCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>
    </div>
  )
}