//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'
import { QueryClient } from 'react-query';

const NAME = 'userLootEnergyBottle'

const queryClient = new QueryClient();

export const userLootEnergyBottleSlice = createSlice({
    name: NAME,
    initialState: {
        count: 0,
    },
    reducers: {
        setUserLootCountEnergyBottle: (state, action) => {
            state.count = action.payload      
        },
    },
})

export const { setUserLootCountEnergyBottle } = userLootEnergyBottleSlice.actions

export default userLootEnergyBottleSlice.reducer