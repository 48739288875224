//@ts-nocheck

import { useQuery } from 'react-query';
import './RitualStoneAllRewardModal.scss'
import { getRewards } from '../../../api/ritualStone';
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader';
import { useTranslation } from "react-i18next";

export default function RitualStoneAllRewardModal() {

    const { t } = useTranslation()

    const { data, isLoading, isError, isSuccess } = useQuery(
        ['ritualStoneRewards'],
        () => getRewards(),
        {
            keepPreviousData: true
        }
    )

    return (
        <div className="modal fade" id='ritualStoneAllRewardModal' tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-transparent border-0">
                    <div className='open-chest-modal-text text-white w-100 d-flex justify-content-start'>
                        {t("ritualStone.text")}
                    </div>
                    <div className='ritual-stone-modal mx-auto'>
                        <div className='d-flex flex-wrap justify-content-center mines-modal-img-container'>
                            {isSuccess &&
                                <>
                                    {
                                        data.ritualStoneRewards.map(item => {
                                            return (
                                                <div className='hero-mine-wait-img position-relative d-flex justify-content-center' style={{ maxWidth: '55px', width: '55px', maxHeight: '55px', height: '55px' }}>
                                                    <img src={`./img/heroes/${item.hero.pathImageFragment}.png`} width={'auto'} height={'100%'} />
                                                    <div className='mines-modal-add-reward position-absolute d-flex justify-content-center align-items-center text-white'>
                                                        {item.countMin + "-" + item.countMax}
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }
                                </>
                            }
                            {isLoading &&
                                <div style={{ paddingBottom: '20px', paddingTop: '20px' }}>
                                    <DataFetchLoader width={80} height={80} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}