//@ts-nocheck

import { MemoCountdown } from '../MemoCountdown/MemoCountdown'
import './BattlesTimer.scss'

export default function BattlesTimer() {
  return (
    <div className='battles-timer w-100 text-white d-flex justify-content-center'>
        <MemoCountdown timeAwait={30000} action={"BATTLESTIMER"}/>
    </div>
  )
}
