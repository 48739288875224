//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './ClanContainerMyClanNoClan.scss'
import { NavLink } from 'react-router-dom';
import сlanContainerMyClanNoclanCreateModal from './ClanContainerMyClanNoclanCreateModal/ClanContainerMyClanNoclanCreateModal';

export default function ClanContainerMyClanNoClan() {

    const { t } = useTranslation()

    return (

        <div className='h-100 d-flex flex-column justify-content-center align-items-center'>

            <div className='clan-container-myclan-noclan-info'>{t("clanContainerMyClanNoClan.noclanInfo")}</div>

            <div className='clan-container-myclan-noclan-button d-flex justify-content-evenly'>

                <div className='d-flex align-items-center'>
                    <div className='clan-container-myclan-profile-button d-flex justify-content-center position-relative' data-bs-toggle="modal" data-bs-target="#createClan">
                        <svg width='94' height='28' viewBox="0 0 130 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M129 19.8599L110.945 38.7166H29.0301L8.30322 19.8599L29.0301 1H110.945L129 19.8599Z" stroke="#B0B0B0" strokeMiterlimit="10" />
                            <path d="M122.697 20.1433L104.641 39H22.7269L2 20.1433L22.7269 1.28339H104.641L122.697 20.1433Z" stroke="#B0B0B0" strokeWidth="2" strokeMiterlimit="10" />
                        </svg>
                        <div className={'clan-container-myclan-noclan-button_text position-absolute top-50 start-50 translate-middle'}>
                            {t("clanContainerMyClanNoClan.create")}
                        </div>
                    </div>
                    <сlanContainerMyClanNoclanCreateModal />
                </div>

            </div>

        </div>

    )
}