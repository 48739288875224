//@ts-nocheck

import HeroOffcanvas from '../../HeroOffcanvas/HeroOffcanvas'
import UserInfoOffcanvas from '../../UserInfoOffcanvas/UserInfoOffcanvas'
import './HeroGridViewUnlocked.scss'

function HeroGridViewUnlocked({ index, userHero }) {

  const rank = userHero.rank
  const image = `/img/heroes/${userHero.hero.pathImage}.png`

  return (
    <div>
      <div className={'hero-grid-view-unlocked position-relative'}>
        <div className={'grid_border'}>
          <div className={'position-relative'} data-bs-toggle="offcanvas" data-bs-target={"#offcanvasBottomHero" + index} aria-controls="offcanvasBottom" style={{ cursor: 'pointer' }}>
            <div className={userHero.hero.id !== 1 ? `rang${rank}` : ''}>
              <img src={image} className={'h-100 w-100'} />
            </div>
          </div>
        </div>
      </div>

      {userHero.hero.nameRu === "User" &&
        <UserInfoOffcanvas index={index} userId={null}/>
      }
      {!userHero.hero.nameRu === "User" &&
        <HeroOffcanvas index={index} userHero={userHero}/>
      }

    </div>
  )
}

export default HeroGridViewUnlocked