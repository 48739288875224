//@ts-nocheck

import './RitualStoneOpen.scss'
import { useTranslation } from 'react-i18next'
import RitualStoneModal from '../RitualStoneModal/RitualStoneModal'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader'
import { useMutation, useQueryClient } from 'react-query'
import { trade } from '../../../api/ritualStone'
import { setRitualStoneHeroes } from '../../../redux/ritualStoneHeroesSlice/ritualStoneHeroesSlice'
import RitualStoneAllRewardModal from '../RitualStoneAllRewardModal/RitualStoneAllRewardModal'
import RitualStoneRewardModal from '../RitualStoneRewardModal/RitualStoneRewardModal'
import { numberFormat } from '../../../utils/NumberFormat'

export default function RitualStoneOpen() {

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const ritualStoneHeroes = useSelector((state) => state.ritualStoneHeroes.heroes)

    const [isLoading, setIsLoading] = useState(false)
    const ritualStoneTradeOnClick = () => {
        if (ritualStoneHeroes.length === 0) return
        if (isLoading) return

        setIsLoading(true)

        const heroIds = ritualStoneHeroes.map(hero => hero.heroId)
        ritualStoneTradeMutation.mutate(heroIds)
    }
    const [userRewards, setUserRewards] = useState([])
    const queryClient = useQueryClient();
    const ritualStoneTradeMutation = useMutation((heroIds) => trade(heroIds), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['userHeroes']).then(async () => {
                queryClient.invalidateQueries(['userHeroesStone']).then(async () => {
                    dispatch(setRitualStoneHeroes([]))
                    setIsLoading(false)
                    setUserRewards(data.data.userRewards)

                    const myModal = new bootstrap.Modal(document.getElementById('ritualStoneRewardModal'));
                    myModal.show();
                })
            })
        },
        onError: () => {
            ToastError(t("toast.toastError"))
        }
    })

    return (
        <>
            <main className='w-100 d-flex ritual-stone-open-background main-container'>
                <div className='ritual-stone-open w-100 h-100 d-flex overflow-auto flex-column align-items-center justify-content-center'>
                    <div className='d-flex flex-column align-items-center' data-bs-toggle="modal" data-bs-target="#riualStoneModal">
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='ritual-stone-open-cell d-flex justify-content-center align-items-center'>
                                <div className='ritual-stone-open-button d-flex justify-content-center align-items-center position-relative'>
                                    {ritualStoneHeroes.length < 1 &&
                                        <svg id="dot" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.7"
                                                d="M11.3487 5.99996C11.3487 6.16737 11.2822 6.31883 11.1704 6.42782C11.0613 6.5394 10.9096 6.60583 10.7393 6.60583H6.61704V10.7248C6.61704 11.0596 6.34298 11.3333 6.00767 11.3333C5.84001 11.3333 5.68829 11.2642 5.57655 11.1552C5.46739 11.0436 5.39829 10.8922 5.39829 10.7247V6.60583H1.27603C0.938047 6.60583 0.666656 6.33477 0.666656 5.99996C0.666656 5.83255 0.733169 5.67841 0.844913 5.56942C0.95407 5.46043 1.1057 5.39133 1.27603 5.39133H5.39821V1.2725C5.39821 0.937682 5.67227 0.666626 6.00758 0.666626C6.17524 0.666626 6.32696 0.735725 6.4387 0.844632C6.54786 0.953622 6.61696 1.10509 6.61696 1.2725V5.39142H10.7391C11.0772 5.39142 11.3487 5.66247 11.3487 5.99996Z"
                                                fill="#B0B0B0" />
                                        </svg>
                                    }
                                    {ritualStoneHeroes.length >= 1 &&
                                    <>
                                        <img src={`./img/heroes/${ritualStoneHeroes[0].hero.pathImageFragment}.png`} width={'auto'} height={'72px'} />
                                        <div className='ritual-stone-container-count-fragments position-absolute d-flex justify-content-center align-items-center text-white'>
                                        {numberFormat(((ritualStoneHeroes[0].count - ritualStoneHeroes[0].count % ritualStoneHeroes[0].hero.ritualStone.count) / ritualStoneHeroes[0].hero.ritualStone.count) * ritualStoneHeroes[0].hero.ritualStone.count)}
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                            <div className='ritual-stone-open-cell d-flex justify-content-center align-items-center'>
                                <div className='ritual-stone-open-button d-flex justify-content-center align-items-center position-relative'>
                                    {ritualStoneHeroes.length < 2 &&
                                        <svg id="dot" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.7"
                                                d="M11.3487 5.99996C11.3487 6.16737 11.2822 6.31883 11.1704 6.42782C11.0613 6.5394 10.9096 6.60583 10.7393 6.60583H6.61704V10.7248C6.61704 11.0596 6.34298 11.3333 6.00767 11.3333C5.84001 11.3333 5.68829 11.2642 5.57655 11.1552C5.46739 11.0436 5.39829 10.8922 5.39829 10.7247V6.60583H1.27603C0.938047 6.60583 0.666656 6.33477 0.666656 5.99996C0.666656 5.83255 0.733169 5.67841 0.844913 5.56942C0.95407 5.46043 1.1057 5.39133 1.27603 5.39133H5.39821V1.2725C5.39821 0.937682 5.67227 0.666626 6.00758 0.666626C6.17524 0.666626 6.32696 0.735725 6.4387 0.844632C6.54786 0.953622 6.61696 1.10509 6.61696 1.2725V5.39142H10.7391C11.0772 5.39142 11.3487 5.66247 11.3487 5.99996Z"
                                                fill="#B0B0B0" />
                                        </svg>
                                    }
                                    {ritualStoneHeroes.length >= 2 &&
                                    <>
                                        <img src={`./img/heroes/${ritualStoneHeroes[1].hero.pathImageFragment}.png`} width={'auto'} height={'72px'} />
                                        <div className='ritual-stone-container-count-fragments position-absolute d-flex justify-content-center align-items-center text-white'>
                                            {numberFormat(((ritualStoneHeroes[1].count - ritualStoneHeroes[1].count % ritualStoneHeroes[1].hero.ritualStone.count) / ritualStoneHeroes[1].hero.ritualStone.count) * ritualStoneHeroes[1].hero.ritualStone.count)}
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='ritual-stone-open-cell d-flex justify-content-center align-items-center'>
                                <div className='ritual-stone-open-button d-flex justify-content-center align-items-center position-relative'>
                                    {ritualStoneHeroes.length < 3 &&
                                        <svg id="dot" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.7"
                                                d="M11.3487 5.99996C11.3487 6.16737 11.2822 6.31883 11.1704 6.42782C11.0613 6.5394 10.9096 6.60583 10.7393 6.60583H6.61704V10.7248C6.61704 11.0596 6.34298 11.3333 6.00767 11.3333C5.84001 11.3333 5.68829 11.2642 5.57655 11.1552C5.46739 11.0436 5.39829 10.8922 5.39829 10.7247V6.60583H1.27603C0.938047 6.60583 0.666656 6.33477 0.666656 5.99996C0.666656 5.83255 0.733169 5.67841 0.844913 5.56942C0.95407 5.46043 1.1057 5.39133 1.27603 5.39133H5.39821V1.2725C5.39821 0.937682 5.67227 0.666626 6.00758 0.666626C6.17524 0.666626 6.32696 0.735725 6.4387 0.844632C6.54786 0.953622 6.61696 1.10509 6.61696 1.2725V5.39142H10.7391C11.0772 5.39142 11.3487 5.66247 11.3487 5.99996Z"
                                                fill="#B0B0B0" />
                                        </svg>
                                    }
                                    {ritualStoneHeroes.length >= 3 &&
                                    <>
                                        <img src={`./img/heroes/${ritualStoneHeroes[2].hero.pathImageFragment}.png`} width={'auto'} height={'72px'} />
                                        <div className='ritual-stone-container-count-fragments position-absolute d-flex justify-content-center align-items-center text-white'>
                                            {numberFormat(((ritualStoneHeroes[2].count - ritualStoneHeroes[2].count % ritualStoneHeroes[2].hero.ritualStone.count) / ritualStoneHeroes[2].hero.ritualStone.count) * ritualStoneHeroes[2].hero.ritualStone.count)}
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                            <div className='ritual-stone-open-cell d-flex justify-content-center align-items-center'>
                                <div className='ritual-stone-open-button d-flex justify-content-center align-items-center position-relative'>
                                    {ritualStoneHeroes.length < 4 &&
                                        <svg id="dot" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.7"
                                                d="M11.3487 5.99996C11.3487 6.16737 11.2822 6.31883 11.1704 6.42782C11.0613 6.5394 10.9096 6.60583 10.7393 6.60583H6.61704V10.7248C6.61704 11.0596 6.34298 11.3333 6.00767 11.3333C5.84001 11.3333 5.68829 11.2642 5.57655 11.1552C5.46739 11.0436 5.39829 10.8922 5.39829 10.7247V6.60583H1.27603C0.938047 6.60583 0.666656 6.33477 0.666656 5.99996C0.666656 5.83255 0.733169 5.67841 0.844913 5.56942C0.95407 5.46043 1.1057 5.39133 1.27603 5.39133H5.39821V1.2725C5.39821 0.937682 5.67227 0.666626 6.00758 0.666626C6.17524 0.666626 6.32696 0.735725 6.4387 0.844632C6.54786 0.953622 6.61696 1.10509 6.61696 1.2725V5.39142H10.7391C11.0772 5.39142 11.3487 5.66247 11.3487 5.99996Z"
                                                fill="#B0B0B0" />
                                        </svg>
                                    }
                                    {ritualStoneHeroes.length >= 4 &&
                                    <>
                                        <img src={`./img/heroes/${ritualStoneHeroes[3].hero.pathImageFragment}.png`} width={'auto'} height={'72px'} />
                                        <div className='ritual-stone-container-count-fragments position-absolute d-flex justify-content-center align-items-center text-white'>
                                            {numberFormat(((ritualStoneHeroes[3].count - ritualStoneHeroes[3].count % ritualStoneHeroes[3].hero.ritualStone.count) / ritualStoneHeroes[3].hero.ritualStone.count) * ritualStoneHeroes[3].hero.ritualStone.count)}
                                        </div>
                                    </>    
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-100 d-flex justify-content-center'>
                        <div className='ritual-stone-container-go d-flex align-items-center position-relative' onClick={ritualStoneTradeOnClick}>
                            <svg width="137" height="42" viewBox="0 0 137 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M135.684 20.8525L116.678 40.7017H30.4525L8.63477 20.8525L30.4525 1H116.678L135.684 20.8525Z" fill="#171027" fill-opacity="0.7" stroke="#B28E4C" stroke-miterlimit="10" />
                                <path d="M129.049 21.1509L110.044 41.0001H23.8177L2 21.1509L23.8177 1.29834H110.044L129.049 21.1509Z" stroke="#B28E4C" stroke-width="2" stroke-miterlimit="10" />
                            </svg>
                            <div className={'ritual-stone-container-go_text text-center position-absolute top-50 start-50 translate-middle'}>
                                {!isLoading &&
                                    t("ritualStone.go")
                                }
                                {isLoading &&
                                    <DataFetchLoader width={23} height={23} color={"#B28E4C"} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <RitualStoneModal />
            <RitualStoneAllRewardModal />
            <RitualStoneRewardModal userRewards={userRewards} />

        </>
    )
}