//@ts-nocheck
import { initMiniApp, retrieveLaunchParams, initInitData, type InitData, postEvent, request, initBackButton, mockTelegramEnv } from '@telegram-apps/sdk';
import { QueryClient } from 'react-query';

const queryClient = new QueryClient();

export const getInitDataRaw = (): string | undefined => {
    try {
        // if(false) {
        //     const initDataRaw = new URLSearchParams([
        //         ['user', JSON.stringify({
        //           id: 5249559931,
        //           first_name: 'Andrew',
        //           last_name: 'Rogue',
        //           username: 'rogue',
        //           language_code: 'en',
        //           is_premium: true,
        //           allows_write_to_pm: true,
        //         })],
        //         ['hash', '89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31'],
        //         ['auth_date', '1716922846'],
        //         ['start_param', 'debug'],
        //         ['chat_type', 'sender'],
        //         ['chat_instance', '8428209589180549439'],
        //       ]).toString();
        //       return initDataRaw
        // }
        const { initDataRaw } = retrieveLaunchParams();
        
        return initDataRaw
    }
    catch {
        return undefined
    }
}

export const getInitData = (): InitData | undefined => {
    try {
        const initData = initInitData();
        return initData
    }
    catch {
        return undefined
    }
}

export const getUserIdByInitData = (): number | undefined => {
    try {
        const initData = initInitData();
        return initData?.user?.id
    }
    catch {
        return undefined
    }
}

export const getStartParamRef = (): string => {

    const initData = getInitData();
    if (initData?.startParam !== undefined) {
        if (initData?.startParam.includes("ref_")) {
            return initData?.startParam.replace('ref_', '');
        } else {
            const telegramAppsLaunchParams = new URLSearchParams(sessionStorage.getItem('telegram-apps/launch-params'))
            return telegramAppsLaunchParams.get('tgWebAppStartParam')?.replace('"', '').replace('ref_', '');
        }
        return "";
    }
    return "";
}

export const isOpenTelegram = async (): boolean => {
    try {

        const [miniApp] = initMiniApp()
        miniApp.ready()

        postEvent('web_app_set_header_color', { color: '#171027' })
        postEvent('web_app_set_bottom_bar_color', { color: '#171027' })
        postEvent('web_app_set_background_color', { color: '#171027' })

        const hash = window.location.hash.slice(1);
        const params = new URLSearchParams(hash);
        const tgWebAppPlatform = params.get('tgWebAppPlatform')
        if(tgWebAppPlatform === "tdesktop")
            return false
        if(tgWebAppPlatform === "macos")
            return false
        if(tgWebAppPlatform === "web")
            return false
        if(tgWebAppPlatform === "weba")
            return false

        postEvent('web_app_expand')
        postEvent('web_app_setup_swipe_behavior', {
            allow_vertical_swipe: false,
        })
        postEvent('web_app_setup_closing_behavior', {
            need_confirmation: true,
        })

        try {
            if(tgWebAppPlatform !== "tdesktop") {
                postEvent('web_app_request_fullscreen')  
            }           
        } catch {
            //
        }

        return true
    }
    catch {
        return false
    }
}