//@ts-nocheck
import { useNavigate, useParams } from 'react-router-dom'
import './ClanView.scss'
import { getById, joinByUserId } from '../../api/clan'
import DataFetchLoader from '../../components/DataFetchLoader/DataFetchLoader'
import DataFetchError from '../../components/DataFetchError/DataFetchError'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import ToastInfo from '../../components/Toasts/ToastInfo/ToastInfo'
import ToastError from '../../components/Toasts/ToastError/ToastError'
import ClanContainerMyClanProfileUser from '../../components/ClanContainer/ClanContainerMyClan/ClanContainerMyClanProfile/ClanContainerMyClanProfileUser/ClanContainerMyClanProfileUser'
import ClanViewUser from '../../components/ClanViewUser/ClanViewUser'

export default function ClanView() {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const { data, isLoading, isError, isSuccess, isFetching } = useQuery(
    ['clanById'],
    () => getById(params.clanId),
    {
      keepPreviousData: true,
    }
  )

  const [clanJoinIsLoading, setClanJoinIsLoading] = useState(false)
  const queryClient = useQueryClient()
  const clanJoinMutation = useMutation((clanId: number) => joinByUserId(clanId), {
    onSuccess: (data) => {
      if (!data.ok) {

        if (data.errorCode === "UserClanAdmin") {
          ToastError(t("clanView.userClanAdmin"), true)
        } else {
          ToastError(data.errorMessage, true)
        }

        setClanJoinIsLoading(false)
        return
      }

      queryClient.invalidateQueries(['clanUserById']).then(() => {
        setClanJoinIsLoading(false)
        localStorage.setItem('activeNavbarItemClan', 'myClan')
        navigate("/clan")
      })
    },
    onError: () => {
      ToastError(t("toast.toastError"))
    }
  })

  const clanViewJoinOnClick = () => {
    if (clanJoinIsLoading) return

    setClanJoinIsLoading(true)

    clanJoinMutation.mutate(params.clanId)
  }

  return (

    <main className='w-100 d-flex flex-column align-items-center main-background main-container'>

      <div className='clan-view-container w-100 h-100 d-flex flex-column overflow-hidden'>

        {(isSuccess && !isFetching) &&
          <>
            <div style={{ paddingBottom: '4px' }}>
              <div className={'clan-view-container-hat justify-content-between d-flex position-relative'}>

                <div className='clan-view-container-logo d-flex flex-column align-items-center'>
                  <img width={'auto'} height={'130px'} src={`/img/clan/flags/${data.clan.pathImage}.png`} style={{ zIndex: 1 }} />
                </div>

                <div className='clan-view-container-info d-flex flex-column w-100' style={{ zIndex: 2 }}>

                  <div className='d-flex flex-column justify-content-between h-100'>
                    <div>
                      <div className='clan-view-container-title d-flex align-items-center'>
                        {data.clan.name}
                      </div>
                      <div className='clan-view-container-description d-flex align-items-center'>
                        {data.clan.description}
                      </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <div className='clan-view-container-stats'>
                        <div>{t("clanContainer.part")}<span className='clan-view-container-stats-count'>{data.clan.ClanUser.length} / {data.clan.maxUsers}</span></div>
                        <div>{t("clanContainer.level")}<span className='clan-view-container-stats-count'>{data.clan.level}</span></div>
                        <div>{t("clanContainer.position")}<span className='clan-view-container-stats-count'>-</span></div>
                      </div>
                      {(data.clan.ClanUser.length < data.clan.maxUsers && !data.clan.userInClan && data.role !== "ADMIN") &&
                        <div onClick={clanViewJoinOnClick} className='clan-container-myclan-profile-button d-flex flex-column align-items-center bottom-0 end-0'>
                          {!clanJoinIsLoading &&
                            <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.00098 17.8014C7.00098 21.1958 7.00098 22.893 8.05548 23.9475C8.82511 24.7171 9.93715 24.9251 11.8014 24.9813M7.00098 8.20061C7.00098 4.80621 7.00098 3.10901 8.05548 2.05451C9.10999 1 10.8072 1 14.2016 1H15.4017C18.7961 1 20.4932 1 21.5478 2.05451C22.6023 3.10901 22.6023 4.80621 22.6023 8.20061V10.6008V15.4012V17.8014C22.6023 21.1958 22.6023 22.893 21.5478 23.9475C20.6256 24.8697 19.212 24.9855 16.6018 25" stroke="#B0B0B0" stroke-linecap="round" />
                              <path d="M1 10.001V16.0015C1 18.8301 1 20.2445 1.87875 21.1233C2.75751 22.002 4.17185 22.002 7.00051 22.002M1.87875 4.87924C2.75751 4.00049 4.17185 4.00049 7.00051 4.00049" stroke="#B0B0B0" stroke-linecap="round" />
                              <path d="M4.60059 13.001H15.4015M15.4015 13.001L12.4012 16.0012M15.4015 13.001L12.4012 10.0007" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          }
                          {clanJoinIsLoading &&
                            <DataFetchLoader width={26} height={26} color={"#B28E4C"} />
                          }
                          <div className='clan-view-container-button_text_green'>
                            {t("clanContainer.buttonIn")}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className='position-absolute top-50 end-0 translate-middle-y'>
                  <img width={'auto'} height={'140px'} src={`/img/clan/background/anonymous.png`} style={{ opacity: '70%' }} />
                </div>
              </div>
            </div>

            <div className='overflow-auto h-100'>
              <div>
                {data.clan.ClanUser.map((itemUser, index) => {
                  return (
                    <ClanViewUser key={index} itemUser={itemUser} />
                  )
                })
                }
              </div>
            </div>
          </>

        }

        {(isLoading && !isFetching) &&
          <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
        }
        {isFetching &&
          <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
        }
        {isError &&
          <DataFetchError text={t("questQuests.dataFetchError")} />
        }

      </div>

    </main >

  )
}