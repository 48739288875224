//@ts-nocheck

import { useSelector } from 'react-redux';
import './BattlesLevel.scss'
import { useTranslation } from 'react-i18next';

export default function BattlesLevel() {

    const { t } = useTranslation()

    const level = useSelector((state) => state.userMonsterHealth.level);

    return (
        <div className='text-white w-100 text-center'>{level} {t("battlesContainer.round")}</div>
    )
}
