//@ts-nocheck

export const getRitualStoneBoxCount = (choosenHeroes) => {

    let countBox = 0
    
    for (const element of choosenHeroes) {        
        const countBoxHero = (element.count - element.count % element.hero.ritualStone.count) / element.hero.ritualStone.count   
        countBox += countBoxHero
    }
    
    return countBox
}