//@ts-nocheck

import "./scss/App.scss";

import { Toaster } from "react-hot-toast";
import { useState, useEffect, useRef } from "react";
import StartLoading from "./components/StartLoading/StartLoading";
import { startInitData } from "./utils/initData";
import { Route, Routes, useNavigate } from "react-router-dom";
import Heroes from "./pages/Heroes/Heroes";
import Refferal from "./pages/Refferal/Refferal";
import Coin from "./pages/Coin/Coin";
import Header from "./components/Header/Header";
import Navbar from "./components/Navbar/Navbar";
import Quest from "./pages/Quest/Quest";
import { useDispatch, useSelector } from "react-redux";
import { incrementUserEnergyCount } from "./redux/userEnergyCountSlice/userEnergyCountSlice";
import { preloadImage, preloadImageWithPromise } from "./utils/preloadImage";
import Rating from "./pages/Rating/Rating";
import Error from "./pages/Error/Error";
import ApiError from "./components/ApiError/ApiError";
import Shop from "./pages/Shop/Shop";
import ApiErrorHash from "./components/ApiErrorHash/ApiErrorHash";

import socketIOClient from "socket.io-client";
import { getApiUrl, getAxiosConfig } from "./api";
import { setUserOfflineReward } from "./redux/userOfflineRewardSlice/userOfflineRewardSlice";
import OfflineRewardOffcanvas from "./components/OfflineRewardOffcanvas/OfflineRewardOffcanvas";
import {
  incrementUserLootCountGold,
  incrementUserLootCountGoldClient,
  incrementUserLootCountGoldSendApi,
} from "./redux/userLootGoldSlice/userLootGoldSlice";
import { I18nextProvider, useTranslation } from "react-i18next";
import { getInitData } from "./utils/telegram";
import { initBackButton } from "@telegram-apps/sdk";
import TonPromo from "./pages/TonPromo/TonPromo";
import { incrementStringUserLootCountGoldAPI } from "./api/userLoot";

import { QueryClient } from 'react-query';
import Clan from "./pages/Clan/Clan";
import ClanView from "./pages/ClanView/ClanView";
import Battles from "./pages/Battles/Battles";
import Mail from "./pages/Mail/Mail";
import Event from "./pages/Event/Event";
import UserBan from "./components/UserBan/UserBan";
import RitualStoneOpen from "./components/RitualStoneContainer/RitualStoneOpen/RitualStoneOpen";
import RuneStoneOpen from "./components/RitualStoneContainer/RuneStoneOpen/RuneStoneOpen";
import RitualStone from "./pages/RitualStone/RitualStone";

const queryClient = new QueryClient();
const [backButton] = initBackButton();

export default function App() {
  //i18n
  const { i18n } = useTranslation();

  //backButton
  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.pathname !== "/") {
      backButton.show();
    } else {
      backButton.hide();
    }
  }, [window.location.pathname]);
  useEffect(() => {
    backButton.on("click", () => {

      try{ 
        const myModalId = document.getElementById("eventModal");
        var myModal = bootstrap.Modal.getInstance(myModalId, {
        });
        myModal.dispose();
      } catch {
      }

      if (window.location.pathname.includes("clan") && window.location.pathname.includes("view")) {
        navigate("/clan")
      } else if (window.location.pathname !== "/" && !window.location.pathname.includes("clan") && !window.location.pathname.includes("view") && !window.location.pathname.includes("ritual-stone")) {
        navigate("/")
      } else if (window.location.pathname === "/clan") {
        navigate("/")
      } else if (window.location.pathname === "/ritual-stone-open") {
        navigate("/ritual-stone")
      } else if (window.location.pathname === "/ritual-stone") {
        navigate("/")
      }
    });
  }, [])

  //ban
  const userBan = useSelector((state) => state.userBan.isBan)

  //error
  const userApiError = useSelector((state) => state.userApiError.error);
  const userApiErrorHash = useSelector((state) => state.userApiErrorHash.error);

  //userOfflineReward
  const userOfflineReward = useSelector((state) => state.userOfflineReward);

  const ips = useSelector((state) => state.userHeroesDamage.IPS);
  const countUserLootGold = useSelector((state) => state.userLootGold.count);

  const dispatch = useDispatch();

  //
  const isLoading = useSelector((state) => state.startLoading.isLoading);

  useEffect(() => {

    if (BigInt(ips) == BigInt("0"))
      return

    const extraHeaders = getAxiosConfig().headers;
    const socket = socketIOClient(getApiUrl(), {
      extraHeaders: extraHeaders,
    });
    socket.connect();
    socket.on("userOfflineReward", async function (data) {
      if (data.timeAbsenceSec !== undefined) {
        dispatch(setUserOfflineReward(data))
      }
    });
    return () => socket.disconnect();

  }, [ips]);

  useEffect(() => {
    if (isLoading)
      return
    const interval = setInterval(() => {
      dispatch(incrementUserEnergyCount());
    }, 20 * 1000);
    return () => clearInterval(interval);
  }, [isLoading]);

  useEffect(() => {
    if (BigInt(ips) == BigInt("0"))
      return

    const intervalIPS = setInterval(() => {
      dispatch(incrementUserLootCountGoldClient(BigInt(ips).toString()));
      dispatch(incrementUserLootCountGoldSendApi(BigInt(ips).toString()));
    }, 1000);
    return () => clearInterval(intervalIPS);
  }, [ips]);

  useEffect(() => {
    i18n.changeLanguage("en");
    const initDataLanguageCode = getInitData()?.user?.languageCode;
    let i18nLanguage =
      localStorage.getItem("i18nLanguage") === undefined
        ? initDataLanguageCode
        : localStorage.getItem("i18nLanguage")
    if (i18nLanguage !== "en" && i18nLanguage !== "ru") i18nLanguage = "en"

    i18n.changeLanguage(i18nLanguage);
  }, []);

  const appRef = useRef(null);
  useEffect(() => {
    appRef.current.style.height = `calc(100% - (${Telegram.WebApp.contentSafeAreaInset.top + Telegram.WebApp.safeAreaInset.top}px)`
  }, [Telegram.WebApp.isFullscreen])

  return (
    <div ref={appRef} id="app" className="w-100 position-relative">
      <I18nextProvider i18n={i18n}>
        {userApiErrorHash && <ApiErrorHash />}
        {userApiError && <ApiError />}
        {userBan && <UserBan />}

        {isLoading ? (
          <StartLoading />
        ) : (
          <>
            <Routes>
              <Route path="/error" element={<Error />} />

              <Route path="/" element={<Coin />} />
              <Route path="/heroes" element={<Heroes />} />
              <Route path="/refferal" element={<Refferal />} />
              <Route path="/quest" element={<Quest />} />

              <Route path="/rating" element={<Rating />} />
              <Route path="/shop" element={<Shop />} />

              <Route path="/ton-promo" element={<TonPromo />} />

              <Route path="/clan" element={<Clan />} />
              <Route path="/clan/:clanId/view" element={<ClanView />} />

              <Route path="/battles" element={<Battles />} />

              <Route path="/mail" element={<Mail />} />
              <Route path="/event" element={<Event />} />

              <Route path="/ritual-stone" element={<RitualStone />} />
              <Route path="/ritual-stone-open" element={<RitualStoneOpen />} />

            </Routes>

            <Toaster />
            <Header />
            <Navbar />

            {userOfflineReward.timeAbsenceSec !== 0 && (
              <OfflineRewardOffcanvas userOfflineReward={userOfflineReward} />
            )}
          </>
        )}
      </I18nextProvider>
    </div>
  );
}
