//@ts-nocheck
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ToastError from "../../../Toasts/ToastError/ToastError";
import {Vibration} from "react-native";
import DataFetchLoader from "../../../DataFetchLoader/DataFetchLoader";
import ModalChestAfterOpen from "../../ShopChest/ShopChestOpenOffcanvas/ModalChestAfterOpen/ModalChestAfterOpen";
import ModalOpenAll from "../../ShopChest/ShopChestOpenOffcanvas/ModalOpenAll/ModalOpenAll";
import ShopSmallChestAfterOpenModal from "../ShopSmallChestAfterOpenModal/ShopSmallChestAfterOpenModal";
import { useQueryClient } from "react-query";

export default function ShopSmallChestOpenOffcanvas({ chest, randomList, prize }) {

    const queryClient = useQueryClient()

    const chestId = chest.id;

    const [prizeList, setPrizeList] = useState(randomList);
    const [isOpen, setIsOpen] = useState(false);
    const [spinCount, setSpinCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!isOpen && randomList && prize) {
            let firstPrizeList = randomList;
            if (prize[0].type === 'GOLD') {
                firstPrizeList[firstPrizeList.length - 3] = 'gold';
            }
            else if (prize[0].type === 'HERO') {
                firstPrizeList[firstPrizeList.length - 3] = 'hero';
            }
            else if (prize[0].type === 'CRYSTAL') {
                firstPrizeList[firstPrizeList.length - 3] = 'crystal';
            }
            else {
                firstPrizeList[firstPrizeList.length - 3] = 'ton';
            }

            setPrizeList(randomList);
            setIsOpen(true);
        }
    }, [randomList, prize]);

    const { t } = useTranslation();

    const openChest = () => {
        const roulette = document.getElementById('roulette' + chestId);
        const openBtn = document.getElementById('open-btn' + chestId);

        openBtn.style.visibility = 'hidden';
        roulette.style.transform = 'translateX(-5400px)';
        const myModal = new bootstrap.Modal(document.getElementById(`prize-open${chestId}`), {
            keyboard: false
        })
        setTimeout(() => {
            setPrizeList((prevVal) => prevVal.slice(prevVal.length - 4, prevVal.length));
            roulette.classList.remove('roulette-animate');
            roulette.style.transform = 'translateX(0)';
            if (localStorage.getItem('isVibration') === 'true') {
                Vibration.vibrate([200]);
            }
            setSpinCount(1);
            myModal.show();
        }, 7000)
    }
    const openChestSpin = () => {
        
        if(isLoading) return

        setIsLoading(true)

        if(prize[0].type === "TON0515" || prize[0].type === "TON0105") {
            queryClient.invalidateQueries(['userTon']).then(() => {
                queryClient.invalidateQueries(['chests']).then(() => {
                    setIsLoading(false)
                    openChest()
                }) 
            })     
        }

        if(prize[0].type === "HERO") {
            queryClient.invalidateQueries(['userHeroes']).then(() => {
                queryClient.invalidateQueries(['chests']).then(() => {   
                    setIsLoading(false)
                    openChest()
                })     
            })     
        }

        if(prize[0].type === "CRYSTAL" || prize[0].type === "GOLD") {
            queryClient.invalidateQueries(['userLoot']).then(() => {                            
                queryClient.invalidateQueries(['chests']).then(() => {
                    setIsLoading(false)
                    openChest()
                })    
            })     
        }
    }

    return (
        <>
            <div className="offcanvas offcanvas-bottom bg-none h-100 w-100 d-flex justify-content-end bg-transparent" tabIndex="-1" id={"chestOpenOffcanvas" + chestId}
                 aria-labelledby="offcanvasBottomLabel" style={{ backdropFilter: 'blur(5px)' }} data-bs-backdrop="false">
                <div className='chest-open-offcanvas-cont h-auto w-100'>
                    <div className="offcanvas-body p-0 bg-none">
                        <div className='chest-open-offcanvas-label text-white'>{t("chestOpenOffcanvas.opened")}</div>

                        <div className='chest-open-offcanvas-roulette d-flex mx-auto position-relative overflow-hidden'>
                            <div id={'roulette' + chestId} className='d-flex roulette-animate roulette-gap'>
                                {prizeList.map(prize => {
                                    return (
                                        <div className='chest-open-offcanvas-hero-spin d-flex justify-content-center' style={{width: '102px'}}>
                                            <img src={`img/chests/smallChest/${prize}.png`} height='90px' width='auto' style={prize === 'hero' ? {maxWidth: '90px', borderRadius: '20px'} : {maxWidth: '90px'}} />
                                        </div>)
                                })}
                            </div>

                            <div
                                className='chest-open-offcanvas-roulette-ramka position-absolute top-0 d-flex justify-content-center align-items-center'>
                                <svg width="120" height="120" viewBox="0 0 122 122" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.7045 107.309C12.9182 112.154 13.6899 116.369 14.9474 121H1.03613V107.065C5.4303 108.167 9.73158 108.819 14.7045 107.309Z"
                                        stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10"/>
                                    <path d="M14.7043 102.765V107.309H19.2414V102.765H14.7043Z" fill="#B28E4C"/>
                                    <path
                                        d="M107.333 107.309C109.119 112.154 108.347 116.37 107.09 121H121.001V107.066C116.607 108.168 112.305 108.819 107.333 107.309Z"
                                        stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10"/>
                                    <path d="M102.796 102.765V107.31H107.333V102.765H102.796Z" fill="#B28E4C"/>
                                    <path
                                        d="M14.6684 14.6912C12.8821 9.84598 13.6538 5.63055 14.9113 1.00002H1V14.9345C5.39417 13.8324 9.69545 13.1811 14.6684 14.6912Z"
                                        stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10"/>
                                    <path d="M19.2053 19.2354V14.6908H14.6683V19.2354H19.2053Z" fill="#B28E4C"/>
                                    <path
                                        d="M107.288 14.6912C109.075 9.84595 108.303 5.63053 107.045 1H120.957V14.9345C116.563 13.8324 112.261 13.1811 107.288 14.6912Z"
                                        stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10"/>
                                    <path d="M107.289 19.2354V14.6908H102.752V19.2354H107.289Z" fill="#B28E4C"/>
                                </svg>
                            </div>
                        </div>

                        <div className='chest-open-offcanvas-btns position-relative d-flex align-items-center d7'>
                            <div className='position-absolute d-flex justify-content-center w-100'>

                                <div id={'open-btn' + chestId}
                                     className='position-relative d-flex justify-content-center align-items-center'
                                     onClick={isOpen && openChestSpin} style={{zIndex: 9999}}>
                                    <svg width="143" height="40" viewBox="0 0 143 40" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M141.757 19.8599L121.888 38.7166H31.7451L8.93628 19.8599L31.7451 1H121.888L141.757 19.8599Z"
                                            stroke="#B28E4C" strokeMiterlimit="10"/>
                                        <path
                                            d="M134.82 20.1433L114.951 39H24.8088L2 20.1433L24.8088 1.28337H114.951L134.82 20.1433Z"
                                            stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10"/>
                                    </svg>
                                    <div
                                        className='position-absolute d-flex justify-content-center align-items-center chest-open-offcanvas-btn-text-big'>
                                        {isLoading && 
                                            <DataFetchLoader width={25} height={25} color={"#B28E4C"}/>
                                        }
                                        {(!isLoading && isOpen) && t("chestOpenOffcanvas.open")}
                                    </div>
                                </div>

                                {(prize !== undefined && spinCount === prize.length) &&
                                    <div className='position-absolute w-100 d-flex justify-content-center'>
                                        <div id={'open-btn' + chestId}
                                             className='position-relative d-flex justify-content-center align-items-center'
                                             data-bs-dismiss="offcanvas" aria-label="Закрыть">
                                            <svg width="143" height="40" viewBox="0 0 143 40" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M141.757 19.8599L121.888 38.7166H31.7451L8.93628 19.8599L31.7451 1H121.888L141.757 19.8599Z"
                                                    stroke="#B28E4C" strokeMiterlimit="10"/>
                                                <path
                                                    d="M134.82 20.1433L114.951 39H24.8088L2 20.1433L24.8088 1.28337H114.951L134.82 20.1433Z"
                                                    stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10"/>
                                            </svg>
                                            <div
                                                className='position-absolute d-flex justify-content-center align-items-center chest-open-offcanvas-btn-text-big'>
                                                {t("chestOpenOffcanvas.close")}
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className='chest-open-offcanvas-heroes'>
                            <div>
                                {t("smallChestOpenOffcanvas.chance")}
                            </div>

                            <div className='d-flex flex-wrap gap-2 justify-content-center'>
                                <div
                                    className='chest-open-offcanvas-hero position-relative d-flex justify-content-center'
                                    style={{width: '60px'}}>
                                    <img src={`img/chests/smallChest/ton.png`} height='55.5px'
                                         width='43.8px' className='mx-auto'/>
                                    <div
                                        className='chest-info-modal-hero-name text-white d-flex justify-content-center align-items-center position-absolute'>
                                        0.5-1.5
                                    </div>
                                </div>
                                <div className='chest-open-offcanvas-hero position-relative'
                                     style={{width: '60px'}}>
                                    <img src={`img/chests/smallChest/hero.png`} height='55.5px'
                                         width={'55.5px'}/>
                                    <div
                                        className='chest-info-modal-hero-name text-white d-flex justify-content-center align-items-center position-absolute'>
                                        {t("chestOpenOffcanvas.valarius")}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='chest-open-offcanvas-heroes'>
                            <div>
                                {t("smallChestOpenOffcanvas.guaranteed")}
                            </div>
                            <div className='d-flex flex-wrap gap-2 justify-content-center'>
                                <div className='d-flex flex-wrap gap-2 justify-content-center'>
                                    <div
                                        className='chest-open-offcanvas-hero position-relative d-flex justify-content-center'
                                        style={{width: '60px'}}>
                                        <img src={`img/chests/smallChest/ton.png`} height='55.5px'
                                             width='43.8px' className='mx-auto'/>
                                        <div
                                            className='chest-info-modal-hero-name text-white d-flex justify-content-center align-items-center position-absolute'>
                                            0.1-0.5
                                        </div>
                                    </div>
                                    <div className='chest-open-offcanvas-hero position-relative'
                                         style={{width: '60px'}}>
                                        <img src={`img/chests/smallChest/gold.png`} height='52.12px'
                                             width='55.5px'/>
                                        <div
                                            className='chest-info-modal-hero-name text-white d-flex justify-content-center align-items-center position-absolute'>
                                            10-200%
                                        </div>
                                    </div>
                                    <div className='chest-open-offcanvas-hero position-relative'
                                         style={{width: '60px'}}>
                                        <img src={`img/chests/smallChest/crystal.png`} height='49.95px'
                                             width='55.5px'/>
                                        <div
                                            className='chest-info-modal-hero-name text-white d-flex justify-content-center align-items-center position-absolute'>
                                            200-3К
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <ShopSmallChestAfterOpenModal prize={prize} chestId={chestId}/>
            </div>
        </>
    )
}