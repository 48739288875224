import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function getByUserId() {
    return await axios.get(
        apiURL + '/api/v1/user-daily-battle', 
        axiosConfig
    )    
}

export async function claim(count: string) {
    const { data } = await axios.post(
        apiURL + '/api/v1/user-daily-battle/claim',
        {
            count: count,    
        },
        axiosConfig
    )    
    return data
}

export async function skip() {
    const { data } = await axios.post(
        apiURL + '/api/v1/user-daily-battle/skip',
        {},
        axiosConfig
    )    
    return data
}