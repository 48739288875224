//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './Shop.scss'
import ShopSmallChest from "../../components/ShopChests/ShopSmallChest/ShopSmallChest";
import { useQuery } from "react-query";
import { get } from "../../api/chest";
import { useState } from "react";
import ShopHeroes from "../../components/ShopHero/ShopHeroes";
import DataFetchError from '../../components/DataFetchError/DataFetchError';
import DataFetchLoader from '../../components/DataFetchLoader/DataFetchLoader';
import ShopChests from '../../components/ShopChests/ShopChests';
import ShopCrystals from '../../components/ShopCrystals/ShopCrystals';
import { useSelector } from 'react-redux';
import Notification from '../../components/Notification/Notification';
import ShopEnergyBottles from '../../components/ShopEnergyBottles/ShopEnergyBottles';

export default function Shop() {

    const { t } = useTranslation();
    const { data, isLoading, isError, isSuccess } = useQuery(
        ['chests'],
        () => get(),
        {
            keepPreviousData: true,
        }
    );

    const [activePage, setActivePage] = useState('crystals')

    const userNotifications = useSelector((state) => state.userNotifications)

    return (
        <main className='w-100 d-flex flex-column align-items-center main-background main-container'>
            <div className='shop-container w-100 h-100 d-flex flex-column overflow-auto'>

                <div className='shop-title'>{t(`shop.${activePage}Title`)}</div>
                <div className='shop-navigate-btns w-100 d-flex justify-content-between'>
                    
                    <div onClick={() => { setActivePage('crystals') }} className={`shop-navigate-btn d-flex justify-content-center ${activePage === 'crystals' ? 'shop-navigate-btn-active' : ''}`}>
                        {t("shop.crystalsPage")}
                    </div>

                    <div onClick={() => { setActivePage('energyBottles') }} className={`shop-navigate-btn d-flex justify-content-center ${activePage === 'energyBottles' ? 'shop-navigate-btn-active' : ''}`}>
                        {t("shop.energyBottlesPage")}
                    </div>
                    
                    <div onClick={() => { setActivePage('chests') }} className={`shop-navigate-btn d-flex justify-content-center position-relative ${activePage === 'chests' ? 'shop-navigate-btn-active' : ''}`}>
                        {t("shop.chestsPage")}
                        {userNotifications.chests !== 0 &&
                            <Notification count={userNotifications.chests}/>
                        }
                    </div>

                    <div onClick={() => { setActivePage('heroes') }} className={`shop-navigate-btn d-flex justify-content-center ${activePage === 'heroes' ? 'shop-navigate-btn-active' : ''}`}>
                        {t("shop.heroesPage")}
                    </div>
                </div>

                {activePage === 'crystals' &&
                    <ShopCrystals/>
                }

                {activePage === 'energyBottles' &&
                    <ShopEnergyBottles/>
                }

                {activePage === 'chests' &&
                    <ShopChests/>
                }

                {activePage === 'heroes' &&
                    <ShopHeroes />
                }
            </div>
        </main>
    )
}