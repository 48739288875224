//@ts-nocheck
import { useTranslation } from "react-i18next";
import './ClanContainerMyClanNoclanCreateModal.scss'
import { useState } from "react";
import DataFetchLoader from "../../../../DataFetchLoader/DataFetchLoader";
import { useMutation, useQueryClient } from "react-query";
import { create } from "../../../../../api/clan";
import ToastError from "../../../../Toasts/ToastError/ToastError";
import { useSelector } from "react-redux";

export default function ClanContainerMyClanNoclanCreateModal() {

    const { t } = useTranslation()
    const countUserLootCrystal = useSelector((state) => state.userLootCrystal.count)

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const nameOnChange = async (e) => {
        setName(e.target.value)
    }
    const descriptionOnChange = async (e) => {
        setDescription(e.target.value)
    }

    const [createIsLoading, setCreateIsLoading] = useState(false)
    const queryClient = useQueryClient()
    const createMutation = useMutation((obj: Object) => create(obj), {
        onSuccess: (data) => {
            if (!data.ok) {

                if (data.errorCode === "NameMin3") {
                    ToastError(t("ClanContainerMyClanNoclanCreateModal.nameMin3"), true)
                }

                if (data.errorCode === "NameMax20") {
                    ToastError(t("ClanContainerMyClanNoclanCreateModal.nameMax20"), true)
                }

                if (data.errorCode === "DescriptionMax120") {
                    ToastError(t("ClanContainerMyClanNoclanCreateModal.descriptionMax120"), true)
                }

                if (data.errorCode === "NameClanOccupied") {
                    ToastError(t("ClanContainerMyClanNoclanCreateModal.nameClanOccupied"), true)
                }

                if (data.errorCode === "OPS") {
                    ToastError(t("toast.toastError"), true)
                }

                if (data.errorCode === "NotEnoughCrystals") {
                    ToastError(t("toast.notEnoughCrystals"), true)
                }

                setCreateIsLoading(false)
                return
            }

            const myModalId = document.getElementById("createClan")
            const myModal = bootstrap.Modal.getInstance(myModalId)
            myModal.hide();

            queryClient.invalidateQueries(['userLoot']).then(() => {
                queryClient.invalidateQueries(['clanUserById']).then(() => {
                    setCreateIsLoading(false)
                })
            })

        },
        onError: () => {
            ToastError(t("toast.toastError"))
        }
    })
    const createOnClick = () => {

        if (BigInt(countUserLootCrystal) < BigInt("1000")) {
            const createClanPriceElem = document.getElementById("createClanPrice")

            createClanPriceElem.classList.toggle("clan-container-create-modal-price-animate")
            setTimeout(() => {
                createClanPriceElem.classList.remove("clan-container-create-modal-price-animate")
            }, 300)

            return
        }

        if (createIsLoading) return

        setCreateIsLoading(true)

        createMutation.mutate({
            name: name,
            description: description,
        })
    }

    return (
        <div className="modal fade" id="createClan" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }}>
            <div className="modal-dialog modal-dialog-centered">

                <div className="modal-content clan-container-create-modal">

                    <div className='clan-container-create-modal-label'>
                        {t("ClanContainerMyClanNoclanCreateModal.label")}
                    </div>

                    <div className='clan-container-create-modal-desc'>
                        {t("ClanContainerMyClanNoclanCreateModal.desc")}
                    </div>

                    <input onChange={e => nameOnChange(e)} className="clan-container-create-modal-input" type="text" id="clanName" placeholder={t("ClanContainerMyClanNoclanCreateModal.clanName")} maxLength="20" minLength="3" />
                    <textarea onChange={e => descriptionOnChange(e)} className="clan-container-create-modal-textarea" id="clanDesc" placeholder={t("ClanContainerMyClanNoclanCreateModal.clanDesc")} maxLength="120" ></textarea>

                    <div className="w-100 d-flex justify-content-center">
                        <div style={{ width: '130px' }} onClick={createOnClick} className='clan-container-create-modal-button d-flex justify-content-center align-items-center' type="button">
                            <svg width="130" height="38" viewBox="0 0 130 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M122.647 19.1946L104.45 37.1168H21.8901L1 19.1946L21.8901 1.26929H104.45L122.647 19.1946Z" stroke="#B28E4C" stroke-miterlimit="10" />
                                <path d="M129 18.9253L110.803 36.8475H28.2431L7.35303 18.9253L28.2431 1H110.803L129 18.9253Z" stroke="#B28E4C" stroke-miterlimit="10" />
                            </svg>

                            <div className='position-absolute d-flex flex-column justify-content-center align-items-center lh-1'>
                                {!createIsLoading &&
                                    <>
                                        {t("ClanContainerMyClanNoclanCreateModal.create")}
                                        <div className="d-flex align-items-center">
                                            <img width={10.52} height={16} src={'/img/loot/crystal-icon.png'} style={{ marginRight: '5px' }} />
                                            <span id="createClanPrice" className={`clan-container-create-modal-button-text ${BigInt(countUserLootCrystal) < BigInt("1000") ? "clan-container-create-modal-button-text-red" : ''}`}>1 000</span>
                                        </div>
                                    </>
                                }
                                {createIsLoading &&
                                    <DataFetchLoader width={20} height={20} />
                                }
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    )

}
