//@ts-nocheck
import './Battles.scss'

import BattlesCanvas from '../../components/BattlesCanvas/BattlesCanvas'
import BattlesOldCoinBalance from '../../components/BattlesOldCoinBalance/BattlesOldCoinBalance'
import BattlesMonsterHealth from '../../components/BattlesMonsterHealth/BattlesMonsterHealth'
import { useDispatch, useSelector } from 'react-redux'
import { setIsStart } from '../../redux/userBattlesStartSlice/userBattlesStartSlice'
import BattlesTimer from '../../components/BattlesTimer/BattlesTimer'
import BattlesRewardModal from '../../components/BattlesRewardModal/BattlesRewardModal'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getByUserId, skip } from '../../api/userDailyBattle'
import DataFetchError from '../../components/DataFetchError/DataFetchError'
import DataFetchLoader from '../../components/DataFetchLoader/DataFetchLoader'
import { MemoCountdown } from '../../components/MemoCountdown/MemoCountdown'
import BattlesLevel from '../../components/BattlesLevel/BattlesLevel'
import { useEffect, useState } from 'react'
import { setFullHealth, setLevel1, setMaxHealth } from '../../redux/userMonsterHealth/userMonsterHealth'
import { setUserOldCoin } from '../../redux/userOldCoinSlice/userOldCoinSlice'
import { CountdownCircleTimer } from "react-countdown-circle-timer"
import { useTranslation } from 'react-i18next'
import BattlesUserStats from '../../components/BattlesUserStats/BattlesUserStats'

const renderTime = ({ remainingTime }) => {
  return (
    <div className="timer">
      <div className="value text-white">{remainingTime}</div>
    </div>
  );
};

export default function Battles() {

  const { t } = useTranslation()

  const { data, isLoading, isError, isSuccess } = useQuery(
    ['userDailyBattle'],
    () => getByUserId(),
    {
      keepPreviousData: true,
    }
  )

  const dispatch = useDispatch()

  const isStart = useSelector((state) => state.userBattlesStart.isStart)

  const [timerStart, setTimerStart] = useState(false)
  const userBattlesStartOnClick = () => {
    setTimerStart(true)
  }

  useEffect(() => {
    dispatch(setIsStart(false))
    dispatch(setLevel1())
    dispatch(setUserOldCoin())
    dispatch(setMaxHealth())
    dispatch(setFullHealth())
  }, [])

  const [skipIsLoading, setSkipIsLoading] = useState(false)
  const queryClient = useQueryClient()
  const userDailyBattleSkipMutation = useMutation(() => skip(), {
    onSuccess: (data) => {
      if (data.ok) {
        queryClient.invalidateQueries(['userDailyBattle']).then(() => {
          queryClient.invalidateQueries(['userLoot']).then(() => {
            setSkipIsLoading(false)
          })
        })
      }
    },
    onError: () => {
      ToastError(t("toast.toastError"))
    }
  })

  const countUserLootEnergyBottle = useSelector((state) => state.userLootEnergyBottle.count)
  const userDailyBattleSkipOnClick = () => {
    if(skipIsLoading) return

    if(countUserLootEnergyBottle - data.data.skipPrice < 0) {
      const shopHeroPriceElem = document.getElementById("userDailyBattleSkipPrice")
      shopHeroPriceElem.classList.toggle("hero-details-modal-price-animate");
      setTimeout(() => {
        shopHeroPriceElem.classList.remove("hero-details-modal-price-animate");
      }, 300);

      return;
    }

    setSkipIsLoading(true)

    userDailyBattleSkipMutation.mutate()
  }

  return (
    <main className='w-100 d-flex flex-column align-items-center monster-background main-container'>
      <BattlesUserStats />
      <section className='w-100 h-100 battles-container d-flex flex-column justify-content-between position-relative'>
        {isSuccess &&
          <>
            <div style={{ marginTop: "10%" }}>
              <BattlesOldCoinBalance />
              {isStart &&
                <div>
                  <div className='d-flex justify-content-evenly'>
                    <div><BattlesLevel /></div>
                    <div><BattlesTimer /></div>
                  </div>
                  <BattlesCanvas />
                </div>
              }
            </div>

            {isStart &&
              <BattlesMonsterHealth />
            }

            {(!isStart && !timerStart) &&
              <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
                <div className={'battles-container-info'} style={{ cursor: 'pointer' }}>
                  <div className='battles-container-rules'>
                    <div className='battles-container-rules-title'>{t("battlesContainer.rules")}</div>
                    <div>{t("battlesContainer.r1")}</div>
                    <div>{t("battlesContainer.r2")}</div>
                    <div>{t("battlesContainer.r3")}</div>
                    <div>{t("battlesContainer.r4")}</div>
                  </div>

                  {data.data.timeAwait === 0 &&
                    <div onClick={userBattlesStartOnClick} className='battles-container-button d-flex justify-content-center position-relative'>
                      <svg width="123" height="38" viewBox="0 0 123 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M122.316 18.8673L105.211 36.7316H27.6073L7.97137 18.8673L27.6073 1H105.211L122.316 18.8673Z" fill="#171027" fill-opacity="0.3" stroke="#B28E4C" stroke-miterlimit="10" />
                        <path d="M116.344 19.1357L99.2392 37H21.636L2 19.1357L21.636 1.26843H99.2392L116.344 19.1357Z" stroke="#B28E4C" stroke-width="2" stroke-miterlimit="10" />
                      </svg>
                      <div className={'battles-container-button-text position-absolute top-50 start-50 translate-middle'}>
                        <span>{t("battlesContainer.summon")}</span>
                      </div>
                    </div>
                  }

                  {data.data.timeAwait !== 0 &&
                    <div onClick={userDailyBattleSkipOnClick} className='battles-container-button d-flex justify-content-center position-relative'>
                      <svg width="123" height="38" viewBox="0 0 123 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M122.316 18.8673L105.211 36.7316H27.6073L7.97137 18.8673L27.6073 1H105.211L122.316 18.8673Z" fill="#171027" fill-opacity="0.3" stroke="#B28E4C" stroke-miterlimit="10" />
                        <path d="M116.344 19.1357L99.2392 37H21.636L2 19.1357L21.636 1.26843H99.2392L116.344 19.1357Z" stroke="#B28E4C" stroke-width="2" stroke-miterlimit="10" />
                      </svg>
                      <div className={'battles-container-button-text position-absolute top-50 start-50 translate-middle'} style={{ lineHeight: '13px' }}>
                        {!skipIsLoading &&
                          <>
                            <MemoCountdown timeAwait={data.data.timeAwait} />
                            <div className='d-flex align-items-center justify-content-center m-0 p-0'>
                              <img src='/img/loot/energy-bottle.png' height={15} width={'auto'} style={{ marginRight: '3px' }} />
                              <div id='userDailyBattleSkipPrice' style={{color: countUserLootEnergyBottle - data.data.skipPrice < 0 ? "rgb(177, 61, 88)" : 'white'}}>{data.data.skipPrice}</div>
                            </div>
                          </>
                        }
                        {skipIsLoading &&
                          <DataFetchLoader width={20} height={20}/>
                        }
                      </div>
                    </div>
                  }

                </div>
              </div>
            }
            {(!isStart && timerStart) &&
              <div className='h-100 w-100 d-flex justify-content-center align-items-center position-absolute'>
                <CountdownCircleTimer
                  isPlaying
                  size={80}
                  strokeWidth={5}
                  duration={3}
                  colors={["#B28E4C", "#B28E4C", "#218D45", "#14572A"]}
                  trailColor={'rgb(178,142,76, 30%)'}
                  colorsTime={[3, 2, 1, 0]}
                  onComplete={() => {
                    setTimerStart(false)
                    dispatch(setIsStart(true))
                  }}
                >
                  {renderTime}
                </CountdownCircleTimer>
              </div>
            }
            <BattlesRewardModal />
          </>
        }
        {isLoading &&
          <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
        }
        {isError &&
          <DataFetchError text={t("questQuests.dataFetchError")} />
        }
      </section>
    </main>
  )
}