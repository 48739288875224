//@ts-nocheck

import './RitualStoneModal.scss'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader'
import { getUserHeroes } from '../../../api/userHero'
import ToastError from '../../Toasts/ToastError/ToastError'
import ToastSuccess from "../../Toasts/ToastSuccess/ToastSuccess";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { numberFormat } from '../../../utils/NumberFormat'
import { useDispatch } from 'react-redux'
import { setRitualStoneHeroes } from '../../../redux/ritualStoneHeroesSlice/ritualStoneHeroesSlice'
import { getRitualStoneBoxCount } from '../../../utils/ritualStoneBox'

export default function RitualStoneOpen() {

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [heroes, setHeroes] = useState([]);
    const { data, isLoading, isError, isSuccess } = useQuery(
        ['userHeroesStone'],
        () => getUserHeroes(),
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                const curHeroes = [];
                for (const element of data.userHeroes) {
                    if (element.heroId == 1)
                        continue
                    if (element.count === 0)
                        continue

                    if(element.hero.ritualStone === null)
                        continue

                    if((element.count - element.count % element.hero.ritualStone.count) / element.hero.ritualStone.count <= 0) {
                        continue    
                    }

                    curHeroes.push(element);
                }
                setHeroes(curHeroes);
                setGetHeroesIsLoading(false)
            }
        }
    )
    const [getHeroesIsLoading, setGetHeroesIsLoading] = useState(true)

    const [choosenHeroes, setChoosenHeroes] = useState([]);

    const myModalEl = document.getElementById('riualStoneModal');
    if (myModalEl) {
        myModalEl.addEventListener('hidden.bs.modal', function (event) {
            setChoosenHeroes([])
        })
    }

    const handleChooseHero = (item) => {
        if (choosenHeroes.includes(item)) {
            setChoosenHeroes((prevVal) => {
                const newHeroes = [];
                for (const val of prevVal) {
                    if (val.id !== item.id) {
                        newHeroes.push(val);
                    }
                }
                return newHeroes;
            });
        }

        else {
            if (choosenHeroes.length >= 4) {
                ToastSuccess(t("minesModalAdd.selected4"), true)
            }
            else {
                setChoosenHeroes((prevVal) => [...prevVal, item]);
            }
        }
    }

    const createOnClick = (heroes) => {
        if (choosenHeroes.length === 0) {
            return
        }
        else {
            dispatch(setRitualStoneHeroes(heroes))
            const myModal = bootstrap.Modal.getInstance(document.getElementById('riualStoneModal'));
            myModal.hide();
        }
    }

    return (
        <div className="modal fade" id='riualStoneModal' tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-transparent border-0">
                    <div className='ritual-stone-modal mx-auto'>
                        <div className='d-flex flex-wrap justify-content-center ritual-stone-modal-img-container'>
                            {getHeroesIsLoading &&
                                <div style={{ height: '100px' }}>
                                    <DataFetchLoader width={50} height={50} color={"#B28E4C"} />
                                </div>
                            }
                            {!getHeroesIsLoading &&
                                heroes.map(item => {
                                    return (
                                        <div onClick={() => handleChooseHero(item)} className='ritual-stone-modal-wait-img position-relative d-flex justify-content-center' style={{ maxWidth: '61px', width: '61px', maxHeight: '61x', height: '61px' }}>
                                            <img id={`${"riualStoneModal"}-${item.hero.nameEn}-img`} src={`./img/heroes/${item.hero.pathImageFragment}.png`} width={'auto'} height={'100%'}
                                                style={choosenHeroes.includes(item) ? { opacity: "50%" } : { opacity: "100%" }} />
                                            <div className='position-absolute w-100 top-0 h-100 d-flex justify-content-center align-items-center'
                                                style={choosenHeroes.includes(item) ? { visibility: "visible", zIndex: 2 } : { visibility: "hidden" }} id={`${"riualStoneModal"}-${item.hero.nameEn}-completed`}>
                                                <svg width="26" height="20" viewBox="0 0 26 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 11.9527L7.4634 18.4936L24.3031 1" stroke="#B28E4C"
                                                        strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <div className='ritual-stone-modal-add-reward position-absolute d-flex justify-content-center align-items-center text-white'>
                                                {numberFormat(item.count)}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='ritual-stone-modal-text-container w-100 d-flex justify-content-between align-items-center justify-content-center'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='ritual-stone-modal-box-text d-flex'>{t("ritualStone.boxText")}</div>
                                    <div className='ritual-stone-modal-box-count d-flex w-100 justify-content-center'>{numberFormat(getRitualStoneBoxCount(choosenHeroes))}</div>
                                </div>
                                <div className='ritual-stone-modal-box-desc d-flex'>{t("ritualStone.boxDesc")}</div>
                            </div>
                            <div id={`riualStoneModal-${"add"}`} className='d-flex position-relative' type='button'
                                onClick={() => createOnClick(choosenHeroes)}>
                                <svg width="110" height="32" viewBox="0 0 110 32" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M103.045 16.1131L87.9291 31H19.3521L2 16.1131L19.3521 1.22369H87.9291L103.045 16.1131Z"
                                        stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10" />
                                    <path
                                        d="M108.322 15.8894L93.206 30.7763H24.6289L7.27689 15.8894L24.6289 1H93.206L108.322 15.8894Z"
                                        stroke="#B28E4C" strokeMiterlimit="10" />
                                </svg>
                                <div className='position-absolute w-100 top-0 bottom-0 my-auto d-flex justify-content-center align-items-center'
                                    style={{ fontSize: "14px" }}>
                                    {t("ritualStone.add")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}