//@ts-nocheck
import './ShopChestOpenOffcanvas.scss';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Vibration } from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";

import ToastError from '../../../Toasts/ToastError/ToastError';
import DataFetchLoader from '../../../DataFetchLoader/DataFetchLoader';
import ModalChestAfterOpen from './ModalChestAfterOpen/ModalChestAfterOpen';
import ModalOpenAll from './ModalOpenAll/ModalOpenAll';

export default function ShopChestOpenOffcanvas({ chest, randomList, prize }) {

    const chestId = chest.id;
    const chestHeroes = chest.chestHeroes;

    const [prizeList, setPrizeList] = useState(randomList);
    const [isOpen, setIsOpen] = useState(false);
    const [spinCount, setSpinCount] = useState(0);

    useEffect(() => {
        if (!isOpen && randomList.length !== 0 && prize) {
            let firstPrizeList = randomList;
            firstPrizeList[firstPrizeList.length - 3] = prize[spinCount];
            setPrizeList(randomList);
            setIsOpen(true);
        }
    }, [randomList, prize]);

    const { t } = useTranslation();

    const myOffcanvas = document.getElementById("chestOpenOffcanvas" + chestId)
    if (myOffcanvas) {
        myOffcanvas.addEventListener('hidden.bs.offcanvas', function () {
            setSpinCount(0);
        })
    }


    const generateRandomList = () => {
        const randomListNew = [];
        for (let i = 0; i < 50; i++) {
            randomListNew.push(chestHeroes[Math.floor(Math.random() * chestHeroes.length)]);
        }
        randomListNew[randomListNew.length - 3] = prize[spinCount];
        return (randomListNew);
    }

    const openChestSpin = () => {
        if (spinCount === prize.length) {
            ToastError("Все коробки открыты");
            return;
        }

        const handleClickAllElem = document.getElementById('handleClickAll' + chestId)
        handleClickAllElem.style.visibility = 'hidden'

        const myModal = new bootstrap.Modal(document.getElementById(`prize-open${chestId}`), {
            keyboard: false
        })
        const roulette = document.getElementById('roulette' + chestId);
        const openBtn = document.getElementById('open-btn' + chestId);

        if (prizeList.length === 50) {
            openBtn.style.visibility = 'hidden';
            roulette.style.transform = 'translateX(-5400px)';
            setTimeout(() => {
                setPrizeList((prevVal) => prevVal.slice(prevVal.length - 4, prevVal.length));
                roulette.classList.remove('roulette-animate');
                roulette.style.transform = 'translateX(0)';
                if (localStorage.getItem('isVibration') === 'true') {
                    Vibration.vibrate([200]);
                }
                openBtn.style.visibility = 'visible';
                myModal.show();

                setSpinCount((prevVal) => {
                    return prevVal + 1
                });

            }, 7000)
        }

        else {
            openBtn.style.visibility = 'hidden';
            const newList = generateRandomList();
            newList[0] = prizeList[0];
            newList[1] = prizeList[1];
            newList[2] = prizeList[2];
            newList[3] = prizeList[3];
            setPrizeList(newList);
            setTimeout(() => {
                roulette.classList.add('roulette-animate');
                roulette.style.transform = 'translateX(-5400px)';
            });
            setTimeout(() => {
                setPrizeList((prevVal) => prevVal.slice(prevVal.length - 4, prevVal.length));
                roulette.classList.remove('roulette-animate');
                roulette.style.transform = 'translateX(0)';
                if (localStorage.getItem('isVibration') === 'true') {
                    Vibration.vibrate([200]);
                }
                openBtn.style.visibility = 'visible';
                myModal.show();

                setSpinCount((prevVal) => {
                    return prevVal + 1
                });

            }, 7000)
        }
    }

    const handleClickAll = () => {
        if (spinCount === prize.length) {
            ToastError("Все коробки открыты");
            return;
        }
        setSpinCount(prize.length);
        const myModal = new bootstrap.Modal(document.getElementById(`prize-open-all${chestId}`), {
            keyboard: false
        })
        myModal.show();
    }

    return (
        <>
            <div className="offcanvas offcanvas-bottom bg-none h-100 w-100 d-flex justify-content-end bg-transparent" tabIndex="-1" id={"chestOpenOffcanvas" + chestId}
                aria-labelledby="offcanvasBottomLabel" style={{ backdropFilter: 'blur(5px)' }} data-bs-backdrop="false">
                <div className='chest-open-offcanvas-cont h-auto w-100'>
                    <div className="offcanvas-body p-0 bg-none">
                        <div className='chest-open-offcanvas-label text-white'>{t("chestOpenOffcanvas.opened")} {isOpen && spinCount + "/" + prize.length} </div>

                        <div className='chest-open-offcanvas-roulette d-flex mx-auto position-relative overflow-hidden'>
                            <div id={'roulette' + chestId} className='d-flex roulette-animate roulette-gap'>
                                {prizeList.map(hero => {
                                    return (<div className='chest-open-offcanvas-hero-spin d-flex'>
                                        <img src={`img/heroes/${hero.countMin !== hero.countMax ? hero.hero.pathImageFragment : hero.hero.pathImage}.png`} height='90px' width='90px'
                                            style={{ borderRadius: '20px' }} />
                                    </div>)
                                })}
                            </div>

                            <div
                                className='chest-open-offcanvas-roulette-ramka position-absolute top-0 d-flex justify-content-center align-items-center'>
                                <svg width="120" height="120" viewBox="0 0 122 122" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.7045 107.309C12.9182 112.154 13.6899 116.369 14.9474 121H1.03613V107.065C5.4303 108.167 9.73158 108.819 14.7045 107.309Z"
                                        stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10" />
                                    <path d="M14.7043 102.765V107.309H19.2414V102.765H14.7043Z" fill="#B28E4C" />
                                    <path
                                        d="M107.333 107.309C109.119 112.154 108.347 116.37 107.09 121H121.001V107.066C116.607 108.168 112.305 108.819 107.333 107.309Z"
                                        stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10" />
                                    <path d="M102.796 102.765V107.31H107.333V102.765H102.796Z" fill="#B28E4C" />
                                    <path
                                        d="M14.6684 14.6912C12.8821 9.84598 13.6538 5.63055 14.9113 1.00002H1V14.9345C5.39417 13.8324 9.69545 13.1811 14.6684 14.6912Z"
                                        stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10" />
                                    <path d="M19.2053 19.2354V14.6908H14.6683V19.2354H19.2053Z" fill="#B28E4C" />
                                    <path
                                        d="M107.288 14.6912C109.075 9.84595 108.303 5.63053 107.045 1H120.957V14.9345C116.563 13.8324 112.261 13.1811 107.288 14.6912Z"
                                        stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10" />
                                    <path d="M107.289 19.2354V14.6908H102.752V19.2354H107.289Z" fill="#B28E4C" />
                                </svg>
                            </div>
                        </div>

                        <div className='chest-open-offcanvas-btns position-relative d-flex align-items-center d7'>
                            <div className='position-absolute d-flex justify-content-center w-100'>

                                {(prize !== undefined && spinCount !== prize.length) &&
                                    <>
                                        <div id={'open-btn' + chestId} className='position-relative d-flex justify-content-center align-items-center' onClick={isOpen && openChestSpin} style={{ zIndex: 9999 }}>
                                            <svg width="143" height="40" viewBox="0 0 143 40" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M141.757 19.8599L121.888 38.7166H31.7451L8.93628 19.8599L31.7451 1H121.888L141.757 19.8599Z"
                                                    stroke="#B28E4C" strokeMiterlimit="10" />
                                                <path
                                                    d="M134.82 20.1433L114.951 39H24.8088L2 20.1433L24.8088 1.28337H114.951L134.82 20.1433Z"
                                                    stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10" />
                                            </svg>
                                            <div
                                                className='position-absolute d-flex justify-content-center align-items-center chest-open-offcanvas-btn-text-big'>
                                                {isOpen && t("chestOpenOffcanvas.open")}
                                                {!isOpen && <DataFetchLoader width={25} height={25} color={"#B28E4C"} />}
                                            </div>
                                        </div>

                                        <div id={'handleClickAll' + chestId} className='position-absolute d-flex justify-content-start w-100 h-100' style={{paddingLeft: '8px'}}>
                                            <div onClick={isOpen && handleClickAll} className='position-relative d-flex justify-content-center align-items-center'>
                                                <svg width="98" height="28" viewBox="0 0 98 28" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M96.6233 13.9042L83.0286 26.8062H21.3521L5.74609 13.9042L21.3521 1.00003H83.0286L96.6233 13.9042Z"
                                                        stroke="#B0B0B0" strokeMiterlimit="10" />
                                                    <path
                                                        d="M91.8772 14.098L78.2825 27H16.606L1 14.098L16.606 1.19388H78.2825L91.8772 14.098Z"
                                                        stroke="#B0B0B0" strokeMiterlimit="10" />
                                                </svg>

                                                <div
                                                    className='position-absolute d-flex justify-content-center align-items-center chest-open-offcanvas-btn-text'>
                                                    {t("chestOpenOffcanvas.openAll")}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                                {(prize !== undefined && spinCount === prize.length) &&
                                    < div id={'open-btn' + chestId} className='position-relative d-flex justify-content-center align-items-center' data-bs-dismiss="offcanvas" aria-label="Закрыть">
                                        <svg width="143" height="40" viewBox="0 0 143 40" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M141.757 19.8599L121.888 38.7166H31.7451L8.93628 19.8599L31.7451 1H121.888L141.757 19.8599Z"
                                                stroke="#B28E4C" strokeMiterlimit="10" />
                                            <path
                                                d="M134.82 20.1433L114.951 39H24.8088L2 20.1433L24.8088 1.28337H114.951L134.82 20.1433Z"
                                                stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10" />
                                        </svg>
                                        <div className='position-absolute d-flex justify-content-center align-items-center chest-open-offcanvas-btn-text-big'>
                                            {t("chestOpenOffcanvas.close")}
                                        </div>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className='chest-open-offcanvas-heroes'>
                            <div>
                                {t("chestOpenOffcanvas.chance")}
                            </div>
                            <div className='d-flex flex-wrap gap-2 justify-content-center'>
                                {chestHeroes.map(hero => {
                                    if (hero.countMin === hero.countMax) {
                                        return (<div className='chest-open-offcanvas-hero'>
                                            <img src={`img/heroes/${hero.hero.pathImage}.png`} height='55.5px' width='55.5px' />
                                        </div>)
                                    }
                                })}
                            </div>
                        </div>

                        <div className='chest-open-offcanvas-heroes'>
                            <div>
                                {t("chestOpenOffcanvas.fragments")}
                            </div>
                            <div className='d-flex flex-wrap gap-2 justify-content-center'>
                                {chestHeroes.map(hero => {
                                    if (hero.countMin !== hero.countMax) {
                                        return (<div className='chest-open-offcanvas-hero position-relative'>
                                            <img src={`img/heroes/${hero.hero.pathImageFragment}.png`} height='55.5px' width='55.5px' />
                                            <div className='chest-info-modal-hero-name text-white d-flex justify-content-center align-items-center position-absolute'>
                                                {hero.countMin}-{hero.countMax}
                                            </div>
                                        </div>)
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <ModalChestAfterOpen curPrize={prize} chestId={chestId} curIndex={spinCount} />
            <ModalOpenAll curPrize={prize} chestId={chestId} />
        </>
    )
}