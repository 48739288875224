//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './ClanContainerAllClansItems.scss'
import { NavLink } from 'react-router-dom';

export default function ClanContainerAllClansItems({item}) {

    const { t } = useTranslation()

    return (
        <NavLink className='clan-container-allclans-part w-100 d-flex justify-content-between align-items-center text-decoration-none' to={`/clan/${item.id}/view`}>

            <div className='clan-container-allclans-part-user d-flex align-items-center'>
                
                <img style={{ marginRight: '12px', zIndex: 1 }} width={'auto'} height={'32px'} src={`/img/clan/flags/${item.pathImage}.png`}/>

                {item.name}

            </div>

            <div className='d-flex align-items-center'>
                <div className='clan-container-allclans-part-rank' style={{ marginRight: '6px' }}>
                
                    <p class="text-end">{t("clanContainerAllClansItems.composition")}</p>
                    <p style={{ fontSize: '14px' }} class="text-end">{item.ClanUser.length} {t("clanContainerAllClansItems.of")} {item.maxUsers}</p>

                </div>
            </div>

        </NavLink>
    )
}