//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './UserBan.scss'
import { getInitData } from '../../utils/telegram'

export default function UserBan() {
    const { t } = useTranslation()
    const initData = getInitData()

    return (
        <div className="user-ban w-100 h-100 d-flex justify-content-center align-items-center flex-column position-fixed" style={{ zIndex: 99999999999999 }}>
            {t("userBan", {telegramId: initData?.user?.id})}
        </div>
    )
}