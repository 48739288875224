//@ts-nocheck

import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function trade(heroIds) {
    return axios.post(
        apiURL + '/api/v1/ritual-stone/trade',
        {
            heroIds: heroIds,
        },
        axiosConfig
    )
}

export async function getRewards() {
    const { data } = await axios.post(
        apiURL + '/api/v1/ritual-stone/get-rewards',
        {},
        axiosConfig
    )
    return data
}