//@ts-nocheck

import './MinesContainer.scss';
import MinesContainerAvailable from "./MinesContainerAvailable/MinesContainerAvailable";
import MinesContainerWait from "./MinesContainerWait/MinesContainerWait";
import MinesContainerGet from "./MinesContainerGet/MinesContainerGet";
import { useQuery } from 'react-query';
import { getByUserId } from '../../api/mine';
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader';
import DataFetchError from '../DataFetchError/DataFetchError';
import MinesContainerInfo from './MinesContainerInfo/MinesContainerInfo';

export default function MinesContainer({ mines }) {

    const { data, isLoading, isError, isSuccess } = useQuery(
        ['mine'],
        () => getByUserId(),
        {
            keepPreviousData: true,
        }
    )

    return (
        <>
            {isSuccess &&
                <div className='d-flex gap-2 flex-column mt-2 overflow-scroll'>

                    <MinesContainerInfo/>

                    {data.mines.map((mine, index) => {
                        if (!mine.isComplete && mine.timeAwait == 0) {
                            return (<MinesContainerAvailable mine={mine} key={index} />);
                        }

                        else if (!mine.isComplete && mine.timeAwait > 0) {
                            return (<MinesContainerWait mine={mine} key={index} />);
                        }

                        else if (mine.isComplete) {
                            return (<MinesContainerGet mine={mine} key={index} />);
                        }
                    })}
                </div>
            }
            {isLoading &&
                <div className='w-100 h-100'>
                    <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
                </div>
            }
            {isError &&
                <div className='w-100 h-100'>
                    <DataFetchError text={t("ratingContainer.dataFetchError")} />
                </div>
            }
        </>
    )
}