//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './MinesContainerInfo.scss'

export default function MinesContainerInfo() {

    const { t } = useTranslation()

    return (
        <div className='mines-container-info w-100 position-relative d-flex flex-column justify-content-between'>
            <img style={{zIndex: 1}} className='position-absolute top-50 end-0 translate-middle-y' src='/img/background/mines-container-info.png' height={'100%'} width={'auto'} />
            <div style={{zIndex: 2}} className='mines-container-info-title'>{t("minesContainerInfo.title")}</div>
            <ul style={{zIndex: 2}} className='mines-container-info-descriptions d-flex flex-column justify-content-center m-0'>
              <li className='mines-container-info-description'>{t("minesContainerInfo.descriptionLi1")}</li>
              <li className='mines-container-info-description'>{t("minesContainerInfo.descriptionLi2")}</li>
              <li className='mines-container-info-description'>{t("minesContainerInfo.descriptionLi3")}</li>  
            </ul>
        </div>
    )
}
