//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'

const NAME = "userOldCoinSlice"

const userOldCoinRewards = [
    "25",
    "50",
    "100",
    "150",
    "200",
    "300",
    "500",
    "700",
    "1000",
    "2000",
    "5000",
    "15000",
    "45000",
    "150000",
    "1000000"
]
export const userOldCoinSlice = createSlice({
    name: NAME,
    initialState: {
        count: BigInt("0").toString()
    },
    reducers: {
        setUserOldCoin: (state, action) => {
            state.count = BigInt("0").toString()
        },
        incrementUserUserOldCoin: (state, action) => {
            let rewardCount = new BigNumber(userOldCoinRewards[action.payload.level-1])
            if(action.payload.userHeroBonusEVENTOLDCOIN !== 0) {
                rewardCount = rewardCount.plus(rewardCount.dividedBy(new BigNumber(100)).multipliedBy(new BigNumber(action.payload.userHeroBonusEVENTOLDCOIN.toString())).decimalPlaces(0, BigNumber.ROUND_HALF_EVEN)).toString() 
            }

            const newCount = BigInt(state.count.toString()) + BigInt(rewardCount.toString()) 
            state.count = newCount.toString()            
        },
    },
})

export const { setUserOldCoin, incrementUserUserOldCoin } = userOldCoinSlice.actions

export default userOldCoinSlice.reducer