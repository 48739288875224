//@ts-nocheck

import { getInitData } from '../../../utils/telegram'
import UserInfoOffcanvas from '../../UserInfoOffcanvas/UserInfoOffcanvas'
import './RatingContainerItemFrens.scss'

export default function RatingContainerItemFrens({ user, telegramId, place, count, photoUrl, userId }) {

  const initDataId = getInitData()?.user.id

  return (
    <>
      <div className={`rating-container-item-gold ${place > 3 ? 'rating-container-item-gold-opacity' : ''} ${(initDataId !== undefined && initDataId == telegramId) ? 'rating-container-item-gold-user' : ''} w-100 d-flex justify-content-between align-items-center`} data-bs-toggle="offcanvas" data-bs-target={"#offcanvasBottomHero" + userId} aria-controls="offcanvasBottom">
        <div className='rating-container-item-user d-flex align-items-center'>

          {photoUrl !== null &&
            <img style={{ borderRadius: '4px', marginRight: '12px' }} src={photoUrl} width={30} height={30} />
          }

          {photoUrl === null &&
            <svg style={{ marginRight: '12px' }} width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.817 19.43C11.2905 19.43 10.3327 18.3377 9.88818 17.7236" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9.63756 14.4936C9.63414 13.9193 9.40314 13.3698 8.99503 12.965C8.58692 12.5602 8.03485 12.3329 7.4594 12.3329C6.88396 12.3329 6.33189 12.5602 5.92378 12.965C5.51567 13.3698 5.28467 13.9193 5.28125 14.4936" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7.45983 25.8804C8.77156 25.9837 8.57144 29.4128 6.89555 29.2251C5.21966 29.0373 5.51028 25.7268 7.45983 25.8804Z" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M2.78134 4.3862C3.51598 3.65313 3.78668 2.7347 3.38595 2.33483C2.98523 1.93496 2.06483 2.20508 1.33019 2.93815C0.595546 3.67123 0.32485 4.58966 0.725573 4.98953C1.1263 5.38939 2.04669 5.11927 2.78134 4.3862Z" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.49238 6.69442C5.54432 5.18113 4.50694 3.7254 3.38574 2.33496" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5.13693 7.99941C3.95749 6.69433 0.725586 4.98956 0.725586 4.98956" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M13.8174 19.43C16.3439 19.43 17.3018 18.3377 17.7462 17.7236" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17.9961 14.4936C17.9995 13.9193 18.2305 13.3698 18.6386 12.965C19.0467 12.5602 19.5988 12.3329 20.1742 12.3329C20.7497 12.3329 21.3018 12.5602 21.7099 12.965C22.118 13.3698 22.349 13.9193 22.3524 14.4936" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M13.8169 30.9999C17.7457 30.9999 18.1904 29.7371 18.1904 28.918C18.1904 28.0988 17.3353 25.3571 13.8169 25.3571C10.2984 25.3571 9.44336 28.0989 9.44336 28.918C9.44336 29.737 9.888 30.9999 13.8169 30.9999Z" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.1744 25.8804C18.8627 25.9837 19.0628 29.4128 20.7387 29.2251C22.4146 29.0373 22.124 25.7268 20.1744 25.8804Z" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M26.9084 4.98945C27.3091 4.58958 27.0384 3.67115 26.3038 2.93808C25.5692 2.205 24.6488 1.93489 24.248 2.33475C23.8473 2.73462 24.118 3.65305 24.8527 4.38612C25.5873 5.1192 26.5077 5.38932 26.9084 4.98945Z" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M21.1421 6.69442C22.0902 5.18113 23.1275 3.7254 24.2487 2.33496" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M22.4971 7.99941C23.6765 6.69433 26.9084 4.98956 26.9084 4.98956" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17.1566 4.42085L17.1763 4.42138C17.6499 4.12982 18.0198 3.69721 18.2338 3.18462C17.1244 3.18116 16.0275 3.41794 15.0187 3.87862C15.8791 2.97241 16.6789 2.01081 17.4129 1C17.4129 1 12.6245 2.19454 11.0055 4.25585H11.0182C4.37683 6.07 0.539551 14.0551 0.539551 18.7133C0.539551 23.992 10.2218 25.3572 13.8172 25.3572C17.4125 25.3572 27.0948 23.9923 27.0948 18.7133C27.0948 14.1832 23.4647 6.50769 17.1566 4.42085Z" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          }

          {((place + ". " + user).substring(0, 30))}

        </div>
        <div className='d-flex align-items-center'>
          <div className='rating-container-item-count' style={{ marginRight: '6px' }}>{count}</div>
          <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.71429 9C10.2013 9 12.2143 6.98705 12.2143 4.5C12.2143 2.01295 10.2013 0 7.71429 0C5.22723 0 3.21429 2.01295 3.21429 4.5C3.21429 6.98705 5.22723 9 7.71429 9ZM10.8 10.2857H10.4665C9.6308 10.6875 8.70268 10.9286 7.71429 10.9286C6.72589 10.9286 5.80179 10.6875 4.96205 10.2857H4.62857C2.07321 10.2857 0 12.3589 0 14.9143V16.0714C0 17.1362 0.863839 18 1.92857 18H13.5C14.5647 18 15.4286 17.1362 15.4286 16.0714V14.9143C15.4286 12.3589 13.3554 10.2857 10.8 10.2857ZM19.2857 9C21.4152 9 23.1429 7.27232 23.1429 5.14286C23.1429 3.01339 21.4152 1.28571 19.2857 1.28571C17.1562 1.28571 15.4286 3.01339 15.4286 5.14286C15.4286 7.27232 17.1562 9 19.2857 9ZM21.2143 10.2857H21.0616C20.5031 10.4786 19.9125 10.6071 19.2857 10.6071C18.6589 10.6071 18.0683 10.4786 17.5098 10.2857H17.3571C16.5375 10.2857 15.7821 10.5228 15.1192 10.9045C16.0996 11.9612 16.7143 13.3634 16.7143 14.9143V16.4571C16.7143 16.5455 16.6942 16.6299 16.6902 16.7143H23.7857C24.8504 16.7143 25.7143 15.8504 25.7143 14.7857C25.7143 12.2987 23.7013 10.2857 21.2143 10.2857Z" fill="#B0B0B0" />
          </svg>
        </div>
      </div>
      <UserInfoOffcanvas index={userId} userId={userId}/>
    </>
  )
}