//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'

export const ritualStoneHeroesSlice = createSlice({
    name: "startLoading",
    initialState: {
        heroes: []
    },
    reducers: {
        setRitualStoneHeroes: (state, action) => {
            state.heroes = action.payload;
        },
    },
})

export const { setRitualStoneHeroes } = ritualStoneHeroesSlice.actions

export default ritualStoneHeroesSlice.reducer