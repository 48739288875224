//@ts-nocheck

import { useEffect, useState } from 'react'
import './OfflineRewardOffcanvas.scss'
import { getInitData } from '../../utils/telegram'
import { numberFormat } from '../../utils/NumberFormat'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default function OfflineRewardOffcanvas({ userOfflineReward }) {

    const { t } = useTranslation()

    const initData = getInitData()
    const initDataUser = initData.user
    const ips = useSelector(state => state.userHeroesDamage.IPS)

    useEffect(() => {
        const myOffcanvas = document.getElementById('offlineRewardOffcanvas')
        const bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
        bsOffcanvas.show()
    }, [])

    return (
        <div className={'offcanvas offcanvas-bottom h-100 d-flex justify-content-end bg-transparent'} tabIndex="-1" id={"offlineRewardOffcanvas"} aria-labelledby="offcanvasBottomLabel" style={{backdropFilter: 'blur(10px)'}}>

            <div className='offline-reward-offcanvas h-auto m-0'>
                <div className="position-relative">
                    <svg className='position-absolute' width="15" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" data-bs-dismiss="offcanvas" aria-label="Закрыть" style={{ cursor: 'pointer', zIndex: 2, top: '-8px', right: '4px', }}>
                        <path d="M1.03613 1.00055L11.0361 11" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M1 10.9987L10.9991 1" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className='offline-reward-offcanvas-user w-100'>{t("offlineRewardOffcanvas.welcomeBack")} {(initDataUser?.firstName + (initDataUser?.lastName === "" ? "" : " " + initDataUser?.lastName)).substring(0, 20)}!</div>
                <div className='offline-reward-offcanvas-image-container position-relative'>
                    <img width={'100%'} height={'auto'} style={{ visibility: 'hidden' }} src='/img/background/offline-reward-offcanvas-background.png' />
                    <div className='offline-reward-offcanvas-container-padding w-100 h-100 d-flex flex-column justify-content-between position-absolute top-0 start-0'>
                        <div className='offline-reward-offcanvas-info-container d-flex flex-column justify-content-between'>
                            <div className='offline-reward-offcanvas-info-title w-100'>
                                {t("offlineRewardOffcanvas.title")}
                            </div>
                            <div className='w-100 d-flex justify-content-center align-items-center'>
                                <img width={31} height={34} src='/img/loot/gold-icon.png' style={{ marginRight: '8px' }} />
                                <div className='offline-reward-offcanvas-info-reward'>
                                    {numberFormat((BigInt(ips) * BigInt(userOfflineReward.timeAbsenceSec.toString())).toString())}
                                </div>
                            </div>
                            <div className='offline-reward-offcanvas-info-hours w-100'>{t("offlineRewardOffcanvas.hours")}</div>
                        </div>
                        <div className='w-100 d-flex justify-content-center' data-bs-dismiss="offcanvas" aria-label="Закрыть">
                            <div className='position-relative'>
                                <svg width="170" height="52" viewBox="0 0 170 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M169.105 25.8157L145.348 50.6272H37.5657L10.2936 25.8157L37.5657 1H145.348L169.105 25.8157Z" fill="#171027" fill-opacity="0.3" stroke="#B28E4C" stroke-miterlimit="10" />
                                    <path d="M160.812 26.1885L137.054 51H29.2722L2 26.1885L29.2722 1.3728H137.054L160.812 26.1885Z" stroke="#B28E4C" stroke-width="2" stroke-miterlimit="10" />
                                </svg>
                                <div className='offline-reward-offcanvas-button-text position-absolute top-50 start-50 translate-middle'>
                                    {t("offlineRewardOffcanvas.button")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}