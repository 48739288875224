//@ts-nocheck

import './ClanContainer.scss'
import { useEffect, useState } from 'react'
import ClanContainerMyClan from './ClanContainerMyClan/ClanContainerMyClan'
import ClanContainerAllClans from './ClanContainerAllClans/ClanContainerAllClans'
import { useQuery } from 'react-query'
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../DataFetchError/DataFetchError'
import { useTranslation } from 'react-i18next'

export default function ClanContainer() {

    const { t } = useTranslation()

    const [activeNavbarItem, setActiveNavbarItem] = useState('myClan')

    useEffect(() => {
        if(localStorage.getItem('activeNavbarItemClan') == undefined) {
            localStorage.setItem('activeNavbarItemClan', 'myClan')
            return
        }
        setActiveNavbarItem(localStorage.getItem('activeNavbarItemClan'))    
    }, [])
    useEffect(() => {
        if(localStorage.getItem('activeNavbarItemClan') == undefined) {
            localStorage.setItem('activeNavbarItemClan', 'myClan')
            return
        }
        
        localStorage.setItem('activeNavbarItemClan', activeNavbarItem)
        
    }, [activeNavbarItem])

    return (
        <div className='clan-container w-100 h-100 d-flex flex-column'>

            <div className='clan-container-navbar d-flex justify-content-between'>
                <div onClick={() => setActiveNavbarItem('myClan')} className={`clan-container-navbar-item ${activeNavbarItem === 'myClan' ? 'clan-container-navbar-item-active' : ''} d-flex align-items-center`}>
                    {t("clanContainer.myClan")}
                </div>

                <div onClick={() => setActiveNavbarItem('allCalns')} className={`clan-container-navbar-item ${activeNavbarItem === 'allCalns' ? 'clan-container-navbar-item-active' : ''} d-flex align-items-center`}>
                    {t("clanContainer.allClans")}
                </div>
            </div>

            {activeNavbarItem === 'myClan' &&
                <ClanContainerMyClan />
            }
            {activeNavbarItem === 'allCalns' &&
                <ClanContainerAllClans />
            }

        </div>
    )

}