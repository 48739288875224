//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './MailContainerAllMailReadIt.scss'
import MailContainerModal from '../../MailContainerModal/MailContainerModal'

export default function MailContainerAllMailReadIt({ item }) {

    const { t, i18n } = useTranslation()

    return (
        <>
            <div className='mail-container-allmail-message d-flex justify-content-between w-100 position-relative'>

                <div className='mail-container-allmail-message-envelope d-flex align-items-center' data-bs-toggle="modal" data-bs-target={`#mailModal${item.id}`}>
                    <svg style={{ opacity: '30%' }} width="36" height="26" viewBox="0 0 36 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.6875 25.8749C32.726 25.8749 35.9998 22.6011 35.9998 18.5626C35.9998 14.5241 32.726 11.2502 28.6875 11.2502C24.649 11.2502 21.3751 14.5241 21.3751 18.5626C21.3751 22.6011 24.649 25.8749 28.6875 25.8749Z" fill="#3CB54A" />
                        <path d="M34.8749 17.4375C34.2536 17.4375 33.7499 17.9411 33.7499 18.5625C33.7499 21.3585 31.4833 23.6249 28.6875 23.6249C28.0661 23.6249 27.5625 24.1286 27.5625 24.7499C27.5625 25.3713 28.0661 25.8749 28.6875 25.8749C32.726 25.8749 36 22.601 36 18.5624C35.9999 17.9412 35.4963 17.4375 34.8749 17.4375Z" fill="#0E9347" />
                        <path d="M22.4999 19.6874C23.1213 19.6874 23.6249 19.1838 23.6249 18.5624C23.6249 15.7664 25.8915 13.5 28.6874 13.5C29.3087 13.5 29.8124 12.9963 29.8124 12.375C29.8124 11.7536 29.3087 11.25 28.6874 11.25C24.6488 11.25 21.3749 14.524 21.3749 18.5625C21.3749 19.1838 21.8786 19.6874 22.4999 19.6874Z" fill="#89C763" />
                        <path d="M32.8556 15.5194C32.4165 15.0798 31.7042 15.0794 31.2645 15.5185C31.2643 15.5188 31.264 15.519 31.2636 15.5194L27.5624 19.2263L26.2237 17.8988C26.1916 17.8528 26.1559 17.8095 26.1168 17.7694C25.6777 17.3298 24.9655 17.3294 24.5258 17.7685C24.5255 17.7688 24.5252 17.769 24.5249 17.7694C24.0853 18.2085 24.0849 18.9208 24.524 19.3604C24.5243 19.3607 24.5245 19.361 24.5249 19.3613L26.7749 21.6113C27.214 22.0509 27.9262 22.0513 28.3659 21.6122C28.3662 21.612 28.3665 21.6117 28.3668 21.6113L32.8667 17.1114C33.3032 16.6692 33.2986 15.9569 32.8564 15.5204C32.8561 15.52 32.8558 15.5197 32.8556 15.5194Z" fill="white" />
                        <path d="M32.8556 15.5192C32.7593 15.4249 32.647 15.3487 32.5237 15.2942C32.7142 15.7197 32.6224 16.2186 32.2932 16.5486L27.7932 21.0485C27.3541 21.4881 26.6418 21.4884 26.2022 21.0494C26.2019 21.0491 26.2016 21.0488 26.2013 21.0485L26.7638 21.611C27.2029 22.0506 27.9152 22.0509 28.3548 21.6119C28.3551 21.6116 28.3554 21.6113 28.3557 21.611L32.8557 17.1111C33.2647 16.6775 33.2396 15.9764 32.8556 15.5192Z" fill="#DDDFE1" />
                        <path d="M33.4181 16.0817C33.3218 15.9874 33.2095 15.9112 33.0862 15.8567C33.2767 16.2822 33.1849 16.7811 32.8557 17.1111L28.3557 21.611C27.9166 22.0506 27.2044 22.0509 26.7647 21.6119C26.7644 21.6116 26.7641 21.6113 26.7638 21.611L27.3263 22.1735C27.7654 22.6131 28.4777 22.6134 28.9173 22.1744C28.9176 22.1741 28.9179 22.1738 28.9182 22.1735L33.4182 17.6736C33.8135 17.2431 33.7755 16.5469 33.4181 16.0817Z" fill="#0E9347" />
                        <path d="M19.1983 17.3811C19.8524 12.1405 24.6309 8.42243 29.8714 9.07655C31.806 9.318 33.6206 10.145 35.072 11.4468C35.3081 11.6488 35.6632 11.6211 35.865 11.385C35.9509 11.2846 35.9988 11.157 36.0001 11.0249V1.6875C36.0001 0.755506 35.2446 0 34.3126 0H1.59212C0.713 0 0.000244141 0.712686 0.000244141 1.5918V24.283C0.000244141 25.1621 0.71293 25.8749 1.59212 25.8749H21.1502C21.4608 25.8718 21.7102 25.6175 21.7071 25.3068C21.7058 25.1747 21.6581 25.0472 21.5721 24.9467C19.7233 22.8872 18.8574 20.1277 19.1983 17.3811Z" fill="#FFCB5B" />
                        <path d="M35.4374 2.57062L19.6875 11.4412C18.6605 12.0184 17.4069 12.0184 16.38 11.4412L0.562508 2.57062C0.213055 2.36889 -0.00160811 1.99539 9.07332e-06 1.59187C9.07332e-06 0.712756 0.712695 0 1.59188 0H34.4081C35.2872 0 35.9999 0.712686 35.9999 1.59187C36.0015 1.99532 35.7869 2.36882 35.4374 2.57062Z" fill="#FFDB6F" />
                        <path d="M35.9998 1.63113C35.9877 2.02052 35.7748 2.37588 35.4373 2.57051L19.6875 11.441C18.6605 12.0183 17.4069 12.0183 16.38 11.441L0.562501 2.57051C0.478407 2.52255 0.400923 2.46391 0.331947 2.39613C0.274502 2.33812 0.223595 2.274 0.180072 2.20488V2.17676C0.0769942 2.00688 0.0207444 1.81289 0.0169475 1.61426H2.23626e-06V1.68738V2.81238C-0.000771199 3.20107 0.199127 3.56261 0.528751 3.76863L16.2111 13.5674L16.3911 13.6799C17.3954 14.2966 18.0052 15.3926 17.9999 16.5711V20.2105C17.9999 22.0962 16.4712 23.6248 14.5855 23.6248H5.06256C3.81986 23.6248 2.81257 24.6322 2.81257 25.8748H21.15C21.4607 25.8717 21.71 25.6175 21.707 25.3068C21.7056 25.1747 21.6579 25.0471 21.5719 24.9467C18.0636 20.9991 18.4198 14.955 22.3674 11.4468C25.9903 8.22713 31.449 8.22713 35.0719 11.4468C35.308 11.6488 35.6631 11.6211 35.8649 11.385C35.9508 11.2846 35.9987 11.157 36 11.0249V1.68738C35.9998 1.68738 35.9998 1.65363 35.9998 1.63113Z" fill="#F7B84E" />
                        <path d="M14.9007 7.38562L10.4682 4.92187C9.92447 4.62122 9.72745 3.93672 10.0281 3.393C10.2293 3.02906 10.6149 2.80582 11.0307 2.8125H11.2501C11.5607 2.8125 11.8126 2.56064 11.8126 2.25C11.8126 1.93936 11.5607 1.6875 11.2501 1.6875H4.50015C4.29843 1.68673 4.11168 1.79403 4.01078 1.96875C3.8601 2.24044 3.95812 2.58279 4.22973 2.73354C4.22987 2.73361 4.23001 2.73368 4.23015 2.73375L14.3551 8.35874C14.6042 8.54429 14.9567 8.49268 15.1422 8.2435C15.3278 7.99438 15.2762 7.6419 15.027 7.45642C14.988 7.42745 14.9456 7.40369 14.9007 7.38562Z" fill="#FFE278" />
                        <path d="M13.5 1.6875H16.875C17.1856 1.6875 17.4375 1.93936 17.4375 2.25C17.4375 2.56064 17.1856 2.8125 16.875 2.8125H13.5C13.1894 2.8125 12.9375 2.56064 12.9375 2.25C12.9375 1.93929 13.1894 1.6875 13.5 1.6875Z" fill="#FFE278" />
                        <path d="M19.6536 11.4467C18.6267 12.024 17.3731 12.024 16.3462 11.4467L0.596191 2.59863C0.65434 2.65362 0.716426 2.70438 0.781816 2.75051L16.2055 12.313C17.2959 12.99 18.6758 12.99 19.7661 12.313L35.1898 2.75051C35.2552 2.70438 35.3172 2.65362 35.3754 2.59863L19.6536 11.4467Z" fill="#F19920" />
                    </svg>
                </div>

                <div className='mail-container-allmail-message-body w-100 d-flex align-items-center'>
                    <div className='d-flex flex-column'>
                        <div className='mail-container-allmail-message-body-title'>
                            {i18n.language === 'en' &&
                                (item.messageTemplate.titleEn)
                            }
                            {i18n.language === 'ru' &&
                                (item.messageTemplate.titleRu)
                            }
                        </div>
                        <div className='mail-container-allmail-message-body-short'>
                            {i18n.language === 'en' &&
                                (item.messageTemplate.textEn)
                            }
                            {i18n.language === 'ru' &&
                                (item.messageTemplate.textRu)
                            }
                        </div>
                    </div>

                </div>
            </div>

            <MailContainerModal item={item} />
        </>
    )
}