//@ts-nocheck

import './ShopSmallChest.scss';
import { useTranslation } from "react-i18next";
import ToastInfo from "../../Toasts/ToastInfo/ToastInfo";
import { MemoCountdown } from '../../MemoCountdown/MemoCountdown';
import { useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { open } from '../../../api/chest';
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader';
import ShopSmallChestOpenOffcanvas from './ShopSmallChestOpenOffcanvas/ShopSmallChestOpenOffcanvas';
import ShopSmallChestInfoModal from './ShopSmallChestInfoModal/ShopSmallChestInfoModal';
import { initDataUserNotification } from '../../../utils/initData';

export default function ShopSmallChest({ item }) {

    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const [isLoading, setIsLoading] = useState(false);
    const [prize, setPrize] = useState();
    const [randomList, setRandomList] = useState([]);

    const shopSmallChestOpenMutation = useMutation(
        (chestId: number) => open(chestId),
        {
            onSuccess: async (data) => {
                if(data.data.ok) {
                    await initDataUserNotification()
                    setPrize([data.data.reward]);
                    const offcanvas = document.getElementById(`chestOpenOffcanvas${item.id}`)
                    const bsOffcanvas = new bootstrap.Offcanvas(offcanvas)
                    bsOffcanvas.show()
                    setIsLoading(false) 
                }
            },
        }
    );

    const generateRandomList = () => {
        const randomListNew = [];
        for (let i = 0; i < 50; i++) {
            const randomNumber = Math.floor(1 + Math.random() * (4 + 1 - 1));
            if (randomNumber === 1) {
                randomListNew.push('gold');
            }
            else if (randomNumber === 2) {
                randomListNew.push('crystal');
            }
            else if (randomNumber === 3) {
                randomListNew.push('ton');
            }
            else if (randomNumber === 4) {
                randomListNew.push('hero');
            }
        }
        setRandomList(randomListNew);
    }

    const shopSmallChestOpenOnClick = () => {
        if (!item.daily.isComplete) return
        if (isLoading) return
        generateRandomList();
        shopSmallChestOpenMutation.mutate(1)
        setIsLoading(true)
    }

    return (
        <div className='small-chest-container w-100 d-flex position-relative'>
            <div className={`small-chest-info position-relative ${isLoading ? "p-0" : ""}`}>
                <div className='position-absolute info-logo' data-bs-toggle="modal" data-bs-target={"#chestModal" + item.id}>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse opacity="0.7" cx="8.2227" cy="8.20513" rx="8.2227" ry="8.20513" fill="#B28E4C"/>
                        <path
                            d="M7.76705 12V6.54545H8.60511V12H7.76705ZM8.19318 5.63636C8.02983 5.63636 7.88897 5.58073 7.7706 5.46946C7.65459 5.35819 7.59659 5.22443 7.59659 5.06818C7.59659 4.91193 7.65459 4.77817 7.7706 4.6669C7.88897 4.55563 8.02983 4.5 8.19318 4.5C8.35653 4.5 8.49621 4.55563 8.61222 4.6669C8.73059 4.77817 8.78977 4.91193 8.78977 5.06818C8.78977 5.22443 8.73059 5.35819 8.61222 5.46946C8.49621 5.58073 8.35653 5.63636 8.19318 5.63636Z"
                            fill="white"/>
                    </svg>
                </div>
                {!isLoading &&
                    <>
                        <div className='small-chest-title text-white w-100 d-flex justify-content-center lh-1'>
                            {t("shop.smallChestTitle")}
                        </div>
                        <div className='d-flex justify-content-center' onClick={shopSmallChestOpenOnClick}>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M36.6407 16.6666C36.5789 14.4815 36.371 13.0884 35.671 11.8995C34.6754 10.2087 32.8824 9.26775 29.2962 7.38585L25.9629 5.6366C23.0369 4.10108 21.5739 3.33331 20 3.33331C18.4262 3.33331 16.9632 4.10108 14.0372 5.6366L10.7039 7.38585C7.11776 9.26775 5.32469 10.2087 4.32904 11.8995C3.33337 13.5904 3.33337 15.6944 3.33337 19.9025V20.0975C3.33337 24.3055 3.33337 26.4096 4.32904 28.1005C5.32469 29.7913 7.11776 30.7321 10.7039 32.6141L14.0372 34.3633C16.9632 35.8988 18.4262 36.6666 20 36.6666C21.5739 36.6666 23.0369 35.8988 25.9629 34.3633L29.2962 32.6141C32.8824 30.7321 34.6754 29.7913 35.671 28.1005C36.371 26.9115 36.5789 25.5185 36.6407 23.3333"
                                    stroke="#B0B0B0" stroke-linecap="round"/>
                                <path
                                    d="M35 12.5L28.3333 15.8333M28.3333 15.8333C28.3333 15.8333 27.8255 16.0873 27.5 16.25C24.571 17.7145 20 20 20 20M28.3333 15.8333V21.6667M28.3333 15.8333L12.5 7.5M20 20L5 12.5M20 20V35.8333"
                                    stroke="#B0B0B0" stroke-linecap="round"/>
                            </svg>
                        </div>
                        <div className='small-chest-text d-flex justify-content-center'>

                            {item.daily.isComplete &&
                                t("shop.itemText")
                            }
                            {!item.daily.isComplete &&
                                <div style={{color: "#B28E4C"}}>
                                    <MemoCountdown timeAwait={item.daily.timeAwait} action={"CHEST"}/>
                                </div>
                            }

                        </div>
                    </>
                }
                {isLoading &&
                    <DataFetchLoader width={30} height={30} color={'#B28E4C'}/>
                }

            </div>

            <div className='small-chest-img position-absolute bottom-0'>
                <img className='ShopSmallChest' src='/img/chests/x2/mini-gold-chest.png' height='115px'/>
            </div>
            <ShopSmallChestOpenOffcanvas chest={item} randomList={randomList} prize={prize}/>
            <ShopSmallChestInfoModal chestId={item.id}/>
        </div>
    );
}