//@ts-nocheck

import './CopyRefferalLink.scss'

import { getStartParamRef, getUserIdByInitData } from '../../utils/telegram';
// import { toastSuccess } from '../../utils/toast/toast';
import ToastSuccess from '../Toasts/ToastSuccess/ToastSuccess';
import ToastError from '../Toasts/ToastError/ToastError';
import ToastInfo from '../Toasts/ToastInfo/ToastInfo';
import ToastStatus from '../Toasts/ToastStatus/ToastStatus';
import { useTranslation } from 'react-i18next';

function CopyRefferalLink() {

    const { t } = useTranslation()

    const copyRefferalLink = async () => {
        const refferalUrl = process.env.NODE_ENV == "production" ? process.env.REACT_APP_BOT_URL_PRODUCTION : process.env.REACT_APP_BOT_URL_DEVELOPMENT
        const copyRefferalUrl = `${refferalUrl}${getUserIdByInitData()}`

        await navigator.clipboard.writeText(copyRefferalUrl);

        ToastInfo(t("copyRefferalLink"), false)
    }

    return (
        <button className='copy-refferal-link-btn' onClick={copyRefferalLink}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.50583 24.9965C5.21917 25 3.96055 24.6143 2.88993 23.8885C1.8193 23.1628 0.984987 22.1297 0.493034 20.9205C0.00108166 19.7114 -0.126315 18.3807 0.127044 17.0978C0.380404 15.8148 1.00307 14.6374 1.91593 13.7152L3.84205 11.7563C4.03414 11.5611 4.29455 11.4516 4.56605 11.4516C4.83755 11.4516 5.09795 11.5611 5.29005 11.7563C5.38671 11.852 5.46353 11.9666 5.51598 12.093C5.56844 12.2195 5.59546 12.3553 5.59546 12.4926C5.59546 12.6299 5.56844 12.7657 5.51598 12.8922C5.46353 13.0186 5.38671 13.1332 5.29005 13.2289L3.36393 15.1879C2.53309 16.0366 2.06663 17.1856 2.06663 18.3833C2.06663 19.5811 2.53309 20.7301 3.36393 21.5788C4.21024 22.4009 5.33553 22.8596 6.50583 22.8596C7.67613 22.8596 8.80142 22.4009 9.64771 21.5788L13.4999 17.647C13.9126 17.2288 14.2399 16.7319 14.4634 16.1847C14.6868 15.6376 14.8018 15.0509 14.8018 14.4585C14.8018 13.8661 14.6868 13.2794 14.4634 12.7323C14.2399 12.1851 13.9126 11.6882 13.4999 11.27C13.3082 11.0747 13.2004 10.8097 13.2004 10.5336C13.2004 10.2575 13.3082 9.99265 13.4999 9.79731C13.5942 9.699 13.7068 9.62086 13.8311 9.56753C13.9554 9.51418 14.089 9.48669 14.2239 9.48669C14.3589 9.48669 14.4925 9.51418 14.6168 9.56753C14.7411 9.62086 14.8538 9.699 14.948 9.79731C15.5521 10.4081 16.0318 11.1343 16.3589 11.9342C16.6862 12.7342 16.8547 13.5921 16.8547 14.4585C16.8547 15.3249 16.6862 16.1828 16.3589 16.9828C16.0318 17.7827 15.5521 18.5089 14.948 19.1197L11.0957 23.0515C10.4957 23.6692 9.78104 24.1592 8.99299 24.4932C8.20498 24.8272 7.35951 24.9982 6.50583 24.9965Z" fill="white" />
                <path d="M10.3716 15.4934C10.2366 15.4965 10.1026 15.4709 9.97787 15.4183C9.85315 15.3656 9.74073 15.2871 9.64757 15.1878C9.04167 14.578 8.56067 13.8521 8.2324 13.052C7.90414 12.2519 7.73511 11.3935 7.73511 10.5266C7.73511 9.65964 7.90414 8.8013 8.2324 8.00121C8.56067 7.20114 9.04167 6.4752 9.64757 5.8654L13.4998 1.93361C14.7171 0.695538 16.3682 0 18.0897 0C19.8113 0 21.4623 0.695538 22.6796 1.93361C23.8969 3.17166 24.5809 4.85085 24.5809 6.60174C24.5809 8.35263 23.8969 10.0319 22.6796 11.2699L20.7535 13.2288C20.5614 13.4239 20.301 13.5335 20.0295 13.5335C19.758 13.5335 19.4975 13.4239 19.3055 13.2288C19.2088 13.1331 19.132 13.0185 19.0795 12.8921C19.0271 12.7656 19 12.6298 19 12.4925C19 12.3552 19.0271 12.2193 19.0795 12.0929C19.132 11.9665 19.2088 11.8519 19.3055 11.7561L21.2316 9.79719C22.0624 8.94839 22.5289 7.79944 22.5289 6.60174C22.5289 5.40403 22.0624 4.25509 21.2316 3.40629C20.3853 2.58419 19.26 2.12546 18.0897 2.12546C16.9194 2.12546 15.7941 2.58419 14.9478 3.40629L11.0956 7.33809C10.6796 7.75438 10.3493 8.25069 10.1239 8.7981C9.89837 9.34552 9.78226 9.93307 9.78226 10.5266C9.78226 11.1201 9.89837 11.7077 10.1239 12.255C10.3493 12.8024 10.6796 13.2989 11.0956 13.7151C11.2875 13.9104 11.3951 14.1752 11.3951 14.4514C11.3951 14.7275 11.2875 14.9924 11.0956 15.1878C11.001 15.2852 10.8882 15.3624 10.7639 15.4149C10.6396 15.4673 10.5061 15.4941 10.3716 15.4934Z" fill="white" />
            </svg>
        </button>
    )
}

export default CopyRefferalLink