//@ts-nocheck

import './RitualStoneContainer.scss'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import React, { useState } from 'react'
import ToastInfo from '../Toasts/ToastInfo/ToastInfo'

export default function RitualStoneContainer() {

    const { t } = useTranslation()
    
    return (
        <div className='ritual-stone-container w-100 h-100 d-flex flex-column align-items-center justify-content-center position-relative'>
                <div className='ritual-stone-container-desc w-100 d-flex justify-content-between align-items-center' style={{ zIndex: "2" }}>
                    <div className='d-flex justify-content-center flex-column'>
                        <div className='ritual-stone-container-desc-title'>{t("ritualStone.title")}</div>
                        <div className='ritual-stone-container-desc-text'>
                            <ul style={{listStyle: "none", paddingLeft: "4px"}}>
                                <li>{t("ritualStone.text_1")}</li>
                                <li>{t("ritualStone.text_2")}</li>
                                <li>{t("ritualStone.text_3")}</li>
                            </ul>
                        </div>
                    </div>
                    <NavLink to="/ritual-stone-open">
                        <div className='ritual-stone-container-button'>
                            <div className='ritual-stone-container-button d-flex align-items-center position-relative'>
                                <svg width="123" height="38" viewBox="0 0 123 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M122 18.8204L104.94 36.6378H27.5401L7.95569 18.8204L27.5401 1H104.94L122 18.8204Z" fill="#171027" fill-opacity="0.3" stroke="#B28E4C" stroke-miterlimit="10"/>
                                    <path d="M116.044 19.0881L98.984 36.9055H21.5844L2 19.0881L21.5844 1.2677H98.984L116.044 19.0881Z" stroke="#B28E4C" stroke-width="2" stroke-miterlimit="10"/>
                                </svg>
                                <div className={'ritual-stone-container-button_text text-center position-absolute top-50 start-50 translate-middle'}>
                                    {t("ritualStone.go")}
                                </div>
                            </div>
                        </div>
                    </NavLink>
                </div>

                <div className='ritual-stone-container-desc w-100 d-flex justify-content-between align-items-center' style={{ marginTop: "18px", zIndex: "2" }}>
                    {/* <NavLink to="/rune-stone-open"> */}
                        <div className='ritual-stone-container-button'>
                            <div className='ritual-stone-container-button d-flex align-items-center position-relative' onClick={() => ToastInfo(t("toast.toastInfo"), true)}>
                                <svg width="123" height="38" viewBox="0 0 123 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M122 18.8204L104.94 36.6378H27.5401L7.95569 18.8204L27.5401 1H104.94L122 18.8204Z" fill="#171027" fill-opacity="0.3" stroke="#B28E4C" stroke-miterlimit="10"/>
                                    <path d="M116.044 19.0881L98.984 36.9055H21.5844L2 19.0881L21.5844 1.2677H98.984L116.044 19.0881Z" stroke="#B28E4C" stroke-width="2" stroke-miterlimit="10"/>
                                </svg>
                                <div className={'ritual-stone-container-button_text text-center position-absolute top-50 start-50 translate-middle'}>
                                    {t("runeStone.go")}
                                </div>
                            </div>
                        </div>
                    {/* </NavLink>         */}
                    <div className='d-flex justify-content-center flex-column'>
                        <div className='ritual-stone-container-desc-title'>{t("runeStone.title")}</div>
                        <div className='ritual-stone-container-desc-text'>
                                <ul style={{listStyle: "none", paddingLeft: "4px"}}>
                                    <li>{t("runeStone.text_1")}</li>
                                    <li>{t("runeStone.text_2")}</li>
                                    <li>{t("runeStone.text_3")}</li>
                                </ul>
                        </div>
                    </div>   
                </div>
        </div>
    )
}