//@ts-nocheck

import React from "react";
import Countdown from "react-countdown";
import { useQueryClient } from "react-query";

export const MemoCountdown = React.memo(({ timeAwait, action = "" }) => {

  const queryClient = useQueryClient()
  const MemoCountdownOnComplete = (action = "") => {
    if (action === "") return

    if (action === "CHEST") {
      queryClient.invalidateQueries(['chests']).then(() => {
      })
    }

    if (action === "BATTLESTIMER") {
      const myModal = new bootstrap.Modal(document.getElementById('battlesRewardModal'), {
        keyboard: false
      })
      myModal.show()
    }

  }

  return <Countdown onComplete={() => MemoCountdownOnComplete(action)} daysInHours={true} date={Date.now() + timeAwait} />
});
