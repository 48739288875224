//@ts-nocheck

import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function getActive() {
    const { data } = await axios.get(
        apiURL + '/api/v1/combo-stars',
        axiosConfig
    )
    return data
}

export async function buy(id: number) {
    return axios.post(
        apiURL + '/api/v1/combo-stars/buy',
        {
            id: id,
        },
        axiosConfig
    )
}