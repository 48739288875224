//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'

const NAME = "userMonsterHealth"

const monsterHealths = [
    BigInt("2500000").toString(),  
    BigInt("5000000").toString(),
    BigInt("10000000").toString(),
    BigInt("15000000").toString(),
    BigInt("20000000").toString(),
    BigInt("30000000").toString(),
    BigInt("50000000").toString(),
    BigInt("70000000").toString(),
    BigInt("100000000").toString(),
    BigInt("200000000").toString(),
    BigInt("500000000").toString(),
    BigInt("1500000000").toString(),
    BigInt("4500000000").toString(),
    BigInt("15000000000").toString(),
    BigInt("100000000000").toString(),
]

export const userMonsterHealth = createSlice({
    name: NAME,
    initialState: {
        current: BigInt("2500000").toString(),
        max: BigInt("2500000").toString(),
        level: 1
    },
    reducers: {
        setMaxHealth: (state, action) => {
            state.max = monsterHealths[state.level-1]
        },
        setFullHealth: (state, action) => {
            state.current = state.max
        },
        decrementHealth: (state, action) => {
            state.current = (BigInt(state.current.toString()) - BigInt(action.payload.toString())).toString()   
        },  
        setLevel1: (state, action) => {
            state.level = 1   
        },
        incrementLevel: (state, action) => {
            state.level++   
        },
        setCurrent0: (state, action) => {
            state.current = "0"   
        }
    },
})

export const { setFullHealth, setMaxHealth, decrementHealth, incrementLevel, setLevel1, setCurrent0 } = userMonsterHealth.actions

export default userMonsterHealth.reducer