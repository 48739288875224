//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'

export const userBanSlice = createSlice({
    name: "userBan",
    initialState: {
        isBan: false,
    },
    reducers: {
        setUserBan: (state, action) => {     
            state.isBan = action.payload     
        },
    },
})

export const { setUserBan } = userBanSlice.actions

export default userBanSlice.reducer