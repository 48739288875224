import './EventContainerClans.scss'
import EventContainerDesc from '../EventContainerDesc/EventContainerDesc'

export default function EventContainerClans() {
  return (
    
    <div className={'event-container-clans align-items-center'}>
      <div className='d-flex' style={{paddingBottom: '12px'}}>
        <EventContainerDesc />
      </div>
    </div>

  )
}