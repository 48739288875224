//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'

export const userNotificationsSlice = createSlice({
    name: "userNotificationsSlice",
    initialState: {
        chests:   0,
        messages: 0,
        battle:   0,
    },
    reducers: {
        setUserNotifications: (state, action) => {
            state.chests   = action.payload.chests  
            state.messages = action.payload.messages 
            state.battle   = action.payload.battle  
        }
    },
})

export const { setUserNotifications } = userNotificationsSlice.actions

export default userNotificationsSlice.reducer