import './EventContainerUsers.scss'
import EventContainerDesc from '../EventContainerDesc/EventContainerDesc'

export default function EventContainerUsers() {
  return (
    
    <div className={'event-container-users align-items-center w-100 h-100'}>
      <div className='d-flex flex-column w-100 h-100'>
        <EventContainerDesc />
      </div>
    </div>

  )
}