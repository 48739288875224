//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'

export const userBattlesStart = createSlice({
    name: "userBattlesStart",
    initialState: {
        isStart: false
    },
    reducers: {
        setIsStart: (state, action) => {
            state.isStart = action.payload;
        },
    },
})

export const { setIsStart } = userBattlesStart.actions

export default userBattlesStart.reducer