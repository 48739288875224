//@ts-nocheck

import './ClanContainerAllClans.scss'
import ClanContainerAllClansSearch from './ClanContainerAllClansSearch/ClanContainerAllClansSearch'
import ClanContainerAllClansItems from './ClanContainerAllClansItems/ClanContainerAllClansItems'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { get } from '../../../api/clan'
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../../DataFetchError/DataFetchError'
import { useTranslation } from 'react-i18next'

export default function ClanContainerAllClans() {

  const { t } = useTranslation()

  const [searchName, setSearchName] = useState("")
  const [searchNameIsLoading, setSearchNameIsLoading] = useState(false)

  useEffect(() => {
    setSearchName("")
  }, [])

  const { data, isLoading, isError, isSuccess, isFetching, refetch } = useQuery(
    ['clan'],
    () => get(searchName),
    {
      keepPreviousData: true,
    }
  )

  const searchNameOnChange = async (e) => {
    setSearchName(e.target.value)
  }
  const withdrawalOnClick = async () => {
    if (searchNameIsLoading) return

    setSearchNameIsLoading(true)

    refetch().then(() => {
      setSearchNameIsLoading(false)
    })
  }

  return (
    <>
      <div className='w-100' style={{paddingBottom: '5px'}}>
        <div className='clan-container-allclans-search w-100 d-flex align-items-center position-relative'>

          <svg width="32" height="19" viewBox="0 0 32 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1286 4.52585C11.0858 7.6849 11.4845 11.834 14.0913 14.5463C16.6562 17.2206 20.7826 17.6388 23.8319 15.5337C24.4729 15.0925 25.0444 14.5578 25.527 13.9477C26.0016 13.3476 26.3875 12.6825 26.6728 11.9728C27.2583 10.5149 27.4097 8.91899 27.109 7.37716C26.8129 5.84931 26.0779 4.44113 24.9942 3.32447C23.9268 2.22085 22.552 1.46393 21.0486 1.15213C19.5512 0.846167 17.9962 1.00405 16.5908 1.6047C15.1722 2.21271 13.9667 3.2297 13.1286 4.52585Z" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M25.3131 15.0828L26.5412 13.3971L30.5528 16.3198L29.3248 18.0055L25.3131 15.0828ZM30.208 18.4913L31.286 17.0117C31.4433 17.3452 31.4183 17.7521 31.1858 18.0712C30.9534 18.3903 30.5737 18.5387 30.208 18.4913ZM25.6579 12.9112L24.5799 14.3909C24.4227 14.0573 24.4476 13.6505 24.6801 13.3314C24.9126 13.0122 25.2923 12.8638 25.6579 12.9112ZM6.70001 9.22838C6.70001 9.62326 6.48065 9.96678 6.15715 10.1438V8.31298C6.48064 8.49005 6.70001 8.83361 6.70001 9.22838ZM5.15715 8.18552V10.2712H2.04286V8.18552H5.15715ZM1.04286 10.1438C0.71936 9.96678 0.5 9.62326 0.5 9.22838C0.5 8.83361 0.719372 8.49005 1.04286 8.31298V10.1438ZM6.70001 15.3998C6.70001 15.7947 6.48065 16.1382 6.15715 16.3153V14.4844C6.48064 14.6615 6.70001 15.005 6.70001 15.3998ZM5.15715 14.357V16.4427H2.04286V14.357H5.15715ZM1.04286 16.3153C0.71936 16.1382 0.5 15.7947 0.5 15.3998C0.5 15.005 0.719371 14.6615 1.04286 14.4844V16.3153ZM8.75716 3.05702C8.75716 3.4518 8.53779 3.79535 8.2143 3.97242V2.14162C8.53779 2.31869 8.75716 2.66224 8.75716 3.05702ZM7.2143 2.01416V4.09988H2.04286V2.01416H7.2143ZM1.04286 3.97242C0.71937 3.79535 0.5 3.4518 0.5 3.05702C0.5 2.66224 0.71937 2.31869 1.04286 2.14162V3.97242Z" fill="#B0B0B0" stroke="#B0B0B0" />
          </svg>

          <input className='clan-container-allclans-search-form-input w-100' type="text" placeholder={t("clanContainerAllClansSearch.title")} value={searchName} onChange={e => searchNameOnChange(e)} />

          <button onClick={withdrawalOnClick} className='clan-container-allclans-search-form-button h-100'>
            {t("clanContainerAllClansSearch.search")}
          </button>

        </div>
      </div>

      <div className='clan-container-items-container overflow-auto h-100'>
        <div className={'clan-container-allclans align-items-center'}>
          {(isSuccess && !isFetching) &&

            (data.clans.map((item, index) => {
              return (
                <ClanContainerAllClansItems item={item} />
              )
            })
            )
          }
        </div>
        {(isLoading && !isFetching) &&
          <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
        }
        {isFetching &&
          <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
        }
        {isError &&
          <DataFetchError text={t("questQuests.dataFetchError")} />
        }

      </div>

    </>

  )
}