//@ts-nocheck

import { NavLink } from 'react-router-dom';
import './HeroRitualStone.scss'
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import ToastInfo from '../Toasts/ToastInfo/ToastInfo';
import { getHeroesLevelByUserId } from '../../api/userHero';
import { useQuery } from 'react-query';
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader';
import DataFetchError from '../DataFetchError/DataFetchError';

function HeroRitualStone() {

    const { t } = useTranslation()

    const { data, isLoading, isError, isSuccess } = useQuery(
        ['heroRitualStone'],
        () => getHeroesLevelByUserId(),
        {
            keepPreviousData: true,
        }
    )

    return (
        <div className={'hero-ritualstone justify-content-between align-items-center'}>
            {isSuccess &&
                <div className='d-flex flex-column w-100 justify-content-center h-100' style={{maxWidth: '161px'}}>

                    <div className='hero-ritualstone-container-title text-center'>{t("heroRitualStone.title")}</div>
                    <div className='hero-ritualstone-container-description'>{t("heroRitualStone.desc")}</div>
                    <div className='hero-ritualstone-container-conditions lh-1'>{t("heroRitualStone.cond")}</div>

                    {data.heroes.zargul.level >= 50 &&
                        <NavLink to="/ritual-stone">
                            <div className={'text-decoration-none position-relative'}>
                                <div className='hero-ritualstone-container-button d-flex align-items-center justify-content-center position-relative'>
                                    <svg width='94' height='28' viewBox="0 0 130 40" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M129 19.8599L110.945 38.7166H29.0301L8.30322 19.8599L29.0301 1H110.945L129 19.8599Z"
                                            stroke="#B28E4C" strokeMiterlimit="10" />
                                        <path d="M122.697 20.1433L104.641 39H22.7269L2 20.1433L22.7269 1.28339H104.641L122.697 20.1433Z"
                                            stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10" />
                                    </svg>
                                    <div className={'hero-ritualstone-container-button_text position-absolute top-50 start-50 translate-middle'}>{t("heroRitualStone.button")}</div>
                                </div>
                            </div>
                        </NavLink>        
                    }
                </div>
            }
            {isLoading &&
                <DataFetchLoader width={45} height={45} color={'#B28E4C'} />
            }
            {isError &&
                <DataFetchError text={"Ой! Что-то пошло не так..."} />
            }

            <img className='hero-ritualstone-img' width={194} height={120} src='/img/ritualstone/header-stone.png' />
        </div>

    )
}

export default HeroRitualStone