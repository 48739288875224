//@ts-nocheck

import './Notification.scss'

export default function Notification({count}) {
  return (
    <div className='notification position-absolute top-0 start-100 translate-middle rounded-circle d-flex justify-content-center align-items-center'>
        <div className='notification-count'>{count > 9 ? "9+" : count}</div>
    </div>
  )
}