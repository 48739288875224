//@ts-nocheck

import RefferalFriendsItem from '../RefferalFriendsItem/RefferalFriendsItem'
import './RefferalFriendsContainer.scss'
import { getUserRefferal } from '../../api/refferalReward'
import { useQuery } from 'react-query';
import Countdown from 'react-countdown';
import { useState } from 'react';
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader';
import DataFetchError from '../DataFetchError/DataFetchError';
import { useTranslation } from 'react-i18next';


function RefferalFriendsContainer() {

    const { t } = useTranslation() 

    const [isTimerComplete, setIsTimerComplete] = useState(true)

    const { data, isLoading, isError, isSuccess, refetch } = useQuery(
        ['userRefferal'],
        () => getUserRefferal(),
        {
            keepPreviousData: true,
        }
    )

    const timerComplete = () => {
        setIsTimerComplete(true)
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return;
        } else {
            return <span className='refferal-friends-container-reload-text'>{(seconds < 10 ? '0' : '') + seconds}</span>;
        }
    }

    const onClickGetUserRefferal = () => {
        if(isTimerComplete === false)
            return

        setIsTimerComplete(false)
        refetch()
    }

    return (
        <div className='refferal-friends-container w-100 overflow-hidden'>

            <div className='refferal-friends-container-header d-flex justify-content-between align-items-center'>
                <div className='refferal-friends-container-title'>{t("refferalFriendsContainer.title") + (isSuccess ? data.data.refferals.length : 0)}</div>

                <div className='position-relative' onClick={onClickGetUserRefferal}>

                    {!isTimerComplete &&
                        <div className='position-absolute top-50 start-50 translate-middle'>
                            <Countdown onComplete={timerComplete} date={Date.now() + 10000} renderer={renderer}>
                            </Countdown>
                        </div>
                    }

                    <div style={{ cursor: 'pointer' }} className={!isTimerComplete ? `refferal-friends-container-reload-await` : ''}>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.9271 4.96994C12.7327 4.59864 12.5094 4.2639 12.2894 4.02633C12.562 3.61594 12.7217 3.12437 12.7217 2.59371C12.7214 1.16145 11.5603 0.000356067 10.1281 0C8.69581 0.000356067 7.53471 1.16145 7.53471 2.59367C7.53471 3.12358 7.69443 3.6159 7.96706 4.02629C7.74665 4.26386 7.52371 4.5986 7.3293 4.9699C7.13414 5.34634 6.97224 5.74945 6.9112 6.12878C6.8911 6.25487 6.88161 6.37803 6.88161 6.49719C6.88014 6.89373 6.99456 7.25186 7.19479 7.54059C7.49554 7.97657 7.96005 8.25177 8.47283 8.42351C8.98739 8.59458 9.56192 8.66584 10.128 8.66619C10.883 8.66437 11.6523 8.54228 12.2755 8.21521C12.5865 8.05111 12.862 7.8311 13.0616 7.54059C13.2615 7.25186 13.3759 6.89405 13.3748 6.49719C13.3748 6.37763 13.3653 6.25487 13.3448 6.12878C13.2842 5.74945 13.1223 5.34638 12.9271 4.96994ZM8.79851 1.26416C9.13986 0.922807 9.60765 0.713044 10.1281 0.713044C10.6485 0.713044 11.1167 0.922846 11.458 1.26416C11.7986 1.60551 12.0091 2.07365 12.0091 2.59371C12.0087 3.11412 11.799 3.58156 11.458 3.92326C11.1166 4.26386 10.6485 4.47398 10.1281 4.47398C9.60765 4.47366 9.13986 4.26386 8.79851 3.92326C8.45748 3.58191 8.24736 3.11412 8.24736 2.59371C8.2474 2.07365 8.45752 1.60551 8.79851 1.26416ZM12.474 7.13788C12.2967 7.39773 11.9777 7.60788 11.5578 7.74746C11.14 7.88708 10.6339 7.95359 10.1281 7.95283C9.45415 7.95465 8.77877 7.83296 8.31355 7.58446C8.08036 7.46166 7.90166 7.31108 7.78249 7.13708C7.66373 6.96237 7.59536 6.76286 7.59429 6.49719C7.59429 6.41791 7.60086 6.33162 7.61514 6.23952C7.65423 5.98477 7.78985 5.62625 7.96124 5.29954C8.11435 5.00424 8.29745 4.73308 8.44474 4.56387C8.89789 4.95159 9.48517 5.18698 10.1281 5.18698C10.7713 5.18698 11.3586 4.95162 11.8117 4.56422C11.9587 4.73308 12.1421 5.00424 12.2952 5.29954C12.4666 5.62625 12.6023 5.98477 12.6409 6.23952C12.6556 6.33162 12.6622 6.41712 12.6622 6.49719C12.6611 6.7629 12.5928 6.96245 12.474 7.13788Z" fill="#B0B0B0" />
                            <path d="M6.0459 15.6992C5.85114 15.3279 5.6282 14.9931 5.40744 14.7556C5.68082 14.3455 5.84053 13.8529 5.84053 13.3226C5.84014 11.8899 4.67908 10.7292 3.24682 10.7289C1.81456 10.7293 0.653865 11.89 0.653469 13.3226C0.653469 13.8525 0.813185 14.3448 1.08621 14.7556C0.866199 14.9924 0.642154 15.3271 0.447742 15.6992C0.252578 16.0756 0.0910419 16.4784 0.0296401 16.8577C0.00989819 16.9841 7.4388e-06 17.107 7.4388e-06 17.2261C-0.00106076 17.6223 0.11296 17.9812 0.313228 18.2699C0.614025 18.7055 1.07885 18.9811 1.59127 19.1529C2.10622 19.3243 2.68032 19.3948 3.24682 19.3955C4.00188 19.394 4.7715 19.2716 5.39466 18.9445C5.70491 18.7801 5.98118 18.5601 6.18002 18.2703C6.37993 17.9812 6.49467 17.623 6.4936 17.2269C6.4936 17.107 6.48371 16.9842 6.46361 16.8577C6.40256 16.4784 6.24067 16.0756 6.0459 15.6992ZM1.91727 11.9927C2.25897 11.652 2.72677 11.4423 3.24682 11.4415C3.76723 11.4423 4.23503 11.652 4.57638 11.9927C4.91777 12.334 5.12713 12.8018 5.12749 13.3226C5.12713 13.8426 4.91773 14.3104 4.57638 14.6525C4.23503 14.9931 3.76723 15.2029 3.24682 15.2029C2.72677 15.2029 2.25862 14.9931 1.91687 14.6525C1.57624 14.3104 1.36647 13.8426 1.36647 13.3226C1.36651 12.8018 1.57628 12.334 1.91727 11.9927ZM5.59275 17.8668C5.41547 18.1273 5.09608 18.3371 4.67651 18.4767C4.25916 18.6164 3.75224 18.6829 3.24682 18.6829C2.57291 18.6843 1.89717 18.5622 1.43231 18.3141C1.19881 18.1906 1.02042 18.0404 0.900936 17.866C0.782168 17.6913 0.713803 17.4914 0.713091 17.2261C0.713091 17.1464 0.719658 17.0605 0.734296 16.9688C0.773068 16.7137 0.908294 16.3556 1.08004 16.0289C1.23315 15.7332 1.41664 15.4624 1.56389 15.2928C2.01673 15.6809 2.604 15.9163 3.24686 15.9163C3.88972 15.9163 4.47664 15.6809 4.92983 15.2936C5.07709 15.462 5.26058 15.7332 5.41369 16.0289C5.58547 16.3556 5.7207 16.7138 5.75943 16.9689C5.77403 17.0603 5.78063 17.1465 5.78063 17.2269C5.77949 17.4922 5.71116 17.692 5.59275 17.8668Z" fill="#B0B0B0" />
                            <path d="M20.2266 16.8577C20.1652 16.4784 20.0037 16.0756 19.8085 15.6992C19.6141 15.3272 19.3901 14.9924 19.1701 14.7556C19.4431 14.3447 19.6028 13.8525 19.6028 13.3226C19.6024 11.8899 18.4417 10.7292 17.0094 10.7289C15.5772 10.7293 14.4161 11.89 14.4157 13.3226C14.4157 13.8525 14.5754 14.3448 14.8485 14.7556C14.6285 14.9924 14.4048 15.3279 14.21 15.6995C14.0156 16.0756 13.8537 16.4784 13.7923 16.8577C13.7726 16.9841 13.7627 17.1069 13.7627 17.2268C13.7616 17.623 13.876 17.9811 14.0759 18.2702C14.377 18.7055 14.8412 18.9814 15.3539 19.1528C15.8688 19.3242 16.4434 19.3948 17.0095 19.3955C17.7645 19.394 18.5342 19.2716 19.1573 18.9445C19.4679 18.7801 19.7439 18.56 19.943 18.2699C20.1426 17.9811 20.2573 17.623 20.2563 17.2261C20.2562 17.107 20.246 16.9841 20.2266 16.8577ZM15.6799 11.9927C16.0212 11.652 16.489 11.4423 17.0094 11.4419C17.5295 11.4423 17.9972 11.6521 18.339 11.9927C18.68 12.334 18.8898 12.8018 18.8898 13.3226C18.8898 13.8426 18.68 14.3104 18.339 14.6525C17.9976 14.9931 17.5295 15.2029 17.0094 15.2029C16.489 15.2029 16.0212 14.9931 15.6799 14.6525C15.3385 14.3104 15.1288 13.8426 15.1288 13.3226C15.1287 12.8018 15.3385 12.334 15.6799 11.9927ZM19.3554 17.8668C19.1781 18.1273 18.859 18.3371 18.4391 18.4767C18.0218 18.6164 17.5149 18.6829 17.0094 18.6829C16.3351 18.6843 15.6594 18.5622 15.1949 18.3145C14.9617 18.1909 14.7827 18.0404 14.6635 17.8668C14.5451 17.6917 14.4768 17.4922 14.4757 17.2269C14.4757 17.1468 14.4823 17.0609 14.4965 16.9688C14.5356 16.7137 14.6708 16.3556 14.8426 16.0289C14.9954 15.7332 15.1788 15.4624 15.3262 15.2928C15.7793 15.6809 16.3666 15.9163 17.0095 15.9163C17.6523 15.9163 18.2393 15.6809 18.6924 15.2936C18.8397 15.462 19.0232 15.7332 19.1763 16.0289C19.3481 16.3556 19.4833 16.7138 19.522 16.9689C19.5366 17.0606 19.5433 17.1465 19.5433 17.2262C19.5421 17.4922 19.4741 17.6917 19.3554 17.8668Z" fill="#B0B0B0" />
                            <path d="M4.06044 8.22001L3.28897 8.04641C3.45454 7.50989 3.72499 7.00705 4.08826 6.56992C4.27681 6.34263 4.49097 6.13286 4.72926 5.94573C4.96137 5.76334 5.21351 5.60181 5.4876 5.46769L5.17296 4.82776C4.85242 4.98526 4.55751 5.1739 4.28888 5.38512C4.01075 5.60406 3.76044 5.84924 3.53968 6.11419C3.1033 6.63939 2.78466 7.24494 2.59313 7.88962L1.71021 7.69117L2.59167 9.63035L4.06044 8.22001Z" fill="#B0B0B0" />
                            <path d="M11.1546 18.498C10.8384 18.5707 10.5136 18.6084 10.1876 18.6084C9.94639 18.6084 9.70442 18.5879 9.46396 18.547C9.17301 18.4969 8.88436 18.4165 8.60331 18.3036C8.32957 18.1947 8.06351 18.0562 7.80951 17.8862L7.41333 18.4797C7.71009 18.6778 8.02038 18.8386 8.33831 18.9654C8.66649 19.097 9.00384 19.1913 9.34369 19.2494C9.62399 19.2976 9.90651 19.321 10.1876 19.321C10.5859 19.321 10.9814 19.2713 11.3665 19.1785L11.6227 20L12.8613 18.2677L10.9061 17.7006L11.1546 18.498Z" fill="#B0B0B0" />
                            <path d="M16.0755 6.46027C16.4753 6.88055 16.7914 7.37975 16.995 7.93122C17.0977 8.20789 17.1722 8.49879 17.215 8.79848C17.2442 9.00057 17.2592 9.20598 17.2592 9.41468C17.2592 9.50713 17.2563 9.60031 17.2501 9.69458L17.9616 9.74064C17.9686 9.63136 17.9722 9.5228 17.9722 9.41464C17.9722 9.17125 17.9543 8.93149 17.9211 8.69685C17.8707 8.34675 17.7837 8.00718 17.6638 7.68415C17.4208 7.02559 17.0403 6.43317 16.559 5.93614L17.1653 5.27864L15.0457 5.07288L15.5321 7.04932L16.0755 6.46027Z" fill="#B0B0B0" />
                        </svg>
                    </div>
                </div>
            </div>

            <div className='refferal-friends-container-friends overflow-auto'>
                {isSuccess && data.status == 200 &&
                    <>
                        {data.data.refferals.map((refferal, index) => {
                            return (
                                <RefferalFriendsItem
                                    key={index}
                                    firstName={refferal.firstName}
                                    lastName={refferal.lastName}
                                    username={refferal.username}
                                    isPremium={refferal.isPremium}
                                />
                            )
                        })
                        }
                    </>
                }

                {isLoading &&
                    <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
                }

                {isError &&
                    <DataFetchError text={t("refferalFriendsContainer.dataFetchError")} />
                }
            </div>

        </div>
    )
}

export default RefferalFriendsContainer