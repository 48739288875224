//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'
import { QueryClient } from 'react-query';

const NAME = 'userLootOldCoin'

const queryClient = new QueryClient();

export const userLootOldCoinSlice = createSlice({
    name: NAME,
    initialState: {
        count: BigInt(0).toString(),
    },
    reducers: {
        setUserLootCountOldCoin: (state, action) => {
            state.count = BigInt(action.payload).toString()      
        },
    },
})

export const { setUserLootCountOldCoin } = userLootOldCoinSlice.actions

export default userLootOldCoinSlice.reducer