//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './RitualStoneRewardModal.scss'

export default function RitualStoneRewardModal({ userRewards }) {

    const { t } = useTranslation()

    return (
        <div className="modal fade" id='ritualStoneRewardModal' tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-transparent border-0">
                    <div className='ritual-stone-modal mx-auto'>
                        <div className='ritual-stone-modal-title text-white'>
                            {t("ritualStoneRewardModal.title")}
                        </div>
                        <div className='d-flex flex-wrap justify-content-center mines-modal-img-container'>
                            {
                                userRewards.map(item => {
                                    return (
                                        <div className='hero-mine-wait-img position-relative d-flex justify-content-center' style={{ maxWidth: '55px', width: '55px', maxHeight: '55px', height: '55px' }}>
                                            <img src={`./img/heroes/${item.hero.pathImageFragment}.png`} width={'auto'} height={'100%'} />
                                            <div className='mines-modal-add-reward position-absolute d-flex justify-content-center align-items-center text-white'>
                                                {item.count}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='chest-info-modal-back-btn position-relative text-white d-flex justify-content-center align-items-center' style={{marginTop: '8px'}} type="button" data-bs-dismiss="modal">
                            <svg width="137" height="42" viewBox="0 0 137 42" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M135.684 20.8525L116.678 40.7017H30.4526L8.63489 20.8525L30.4526 1H116.678L135.684 20.8525Z"
                                    fill="#171027" fillOpacity="0.3" stroke="#B28E4C" strokeMiterlimit="10" />
                                <path
                                    d="M129.049 21.1508L110.044 41H23.8177L2 21.1508L23.8177 1.29822H110.044L129.049 21.1508Z"
                                    stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10" />
                            </svg>
                            <div className='position-absolute d-flex justify-content-center align-items-center lh-1'>
                                {t("chestModal.back")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}