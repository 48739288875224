import { useTranslation } from 'react-i18next'
import './ApiErrorHash.scss'
import ApiErrorHashIcon from './ApiErrorHashIcon/ApiErrorHashIcon'

export default function ApiErrorHash() {

    const { t } = useTranslation()

    return (
        <div className="api-error-hash w-100 h-100 d-flex justify-content-center align-items-center flex-column position-fixed" style={{ zIndex: 99999999999999 }}>
            <ApiErrorHashIcon/>
            {t("apiErrorHash")}
        </div>
    )
}
