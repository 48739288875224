//@ts-nocheck

import { useQuery } from "react-query"
import { numberFormat } from "../../utils/NumberFormat"
import "./Header.scss"
import HeaderItem from "./HeaderItem/HeaderItem"

import { useDispatch, useSelector } from 'react-redux'
import { getUserLoot } from "../../api/userLoot"
import { setUserLootCountGold } from "../../redux/userLootGoldSlice/userLootGoldSlice"
import { setUserLootCountCrystal } from "../../redux/userLootCrystalSlice/userLootCrystalSlice"
import { setUserLootCountOldCoin } from "../../redux/userLootOldCoinSlice/userLootOldCoinSlice"
import { setUserLootCountEnergyBottle } from "../../redux/userLootEnergyBottleSlice/userLootEnergyBottleSlice"

function Header() {

  const dispatch = useDispatch()

  const { data, isLoading, isError, isSuccess } = useQuery(
    ['userLoot'],
    () => getUserLoot(),
    {
      keepPreviousData: true,

      onSuccess(data) {
        for (const userLootEl of data.data) {
          if (userLootEl.name === "Gold")
            dispatch(setUserLootCountGold(userLootEl.count))
          if (userLootEl.name === "Crystal")
            dispatch(setUserLootCountCrystal(userLootEl.count))
          if (userLootEl.name === "OldCoin")
            dispatch(setUserLootCountOldCoin(userLootEl.count))
          if (userLootEl.name === "EnergyBottle")
            dispatch(setUserLootCountEnergyBottle(userLootEl.count))
        }
      },
    },
  )

  const countUserLootGold = useSelector((state) => state.userLootGold.count)
  const countUserLootCrystal = useSelector((state) => state.userLootCrystal.count)
  const countUserLootOldCoin = useSelector((state) => state.userLootOldCoin.count)
  const countUserLootEnergyBottle = useSelector((state) => state.userLootEnergyBottle.count)

  return (
    <div id='header' className="w-100 position-absolute" style={{top: 0, zIndex: '1000'}}>
      <div className='header-container d-flex justify-content-between'>
        <HeaderItem count={numberFormat(countUserLootCrystal)} imagePath={'img/loot/header/crystal-icon.png'} disable={false} iconWidth={15} iconHeight={23} />
        <HeaderItem count={numberFormat(countUserLootEnergyBottle)} imagePath={'img/loot/energy-bottle.png'} disable={false} iconWidth={24} iconHeight={23} />
        <HeaderItem count={numberFormat(countUserLootOldCoin)} imagePath={'img/loot/header/stone-icon.png'} disable={false} iconWidth={20} iconHeight={23} />
        <HeaderItem count={numberFormat(countUserLootGold)} imagePath={'img/loot/gold-icon.png'} disable={false} iconWidth={20} iconHeight={23} />
      </div>
    </div>
  )
}

export default Header