//@ts-nocheck

import './MinesModalAdd.scss';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import ToastSuccess from "../../Toasts/ToastSuccess/ToastSuccess";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {create} from "../../../api/mine";
import ToastError from "../../Toasts/ToastError/ToastError";
import { numberFormat } from '../../../utils/NumberFormat';
import { getMineRewardByHeroesByReward } from '../../../utils/mineReward';
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader';
import { getUserHeroes } from '../../../api/userHero';
import { initDataUserHeroesDamage } from '../../../utils/initData';

export default function MinesModalAdd({mineId, reward}) {

    const { t } = useTranslation();

    const [heroes, setHeroes] = useState([]);
    const { data, isLoading, isError, isSuccess } = useQuery(
        ['userHeroes'],
        () => getUserHeroes(),
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                const curHeroes = [];
                for (const element of data.userHeroes) {
                    if (element.status !== "ACTIVE" || element.level === 0 || element.mine.inMine || element.heroId == 1)
                        continue
                    curHeroes.push(element);
                }
                setHeroes(curHeroes);
                setGetHeroesIsLoading(false)
            }
        }
    )
    const [getHeroesIsLoading, setGetHeroesIsLoading] = useState(true)

    const [choosenHeroes, setChoosenHeroes] = useState([]);
    const [addIsLoading, setAddIsLoading] = useState(false)

    const queryClient = useQueryClient();
    const createUserMineMutation = useMutation((dataObj) => create(dataObj), {
        onSuccess: (data) => {
            if(!data.ok) {
                if(data.errorCode === "userMineHeroesNot0") {
                    ToastError(t("minesModalAdd.userMineHeroesNot0"), true)
                }
                return
            }
            queryClient.invalidateQueries(['userHeroes']).then(async () => {
                const isInitDataUserHeroesDamage = await initDataUserHeroesDamage()
                
                queryClient.invalidateQueries(['mine']).then(() => {
                    const myModal = bootstrap.Modal.getInstance(document.getElementById('minesModal' + mineId));
                    myModal.hide();
                    setAddIsLoading(false)
                })
            })
        },
        onError: () => {
            ToastError(t("toast.toastError"))
        }
    })

    const createOnClick = (heroes) => {
        if (choosenHeroes.length < 4) {
            ToastError(t("minesModalAdd.selected4Error"), true)
        }
        else {
            if(addIsLoading) return

            setAddIsLoading(true)

            const heroIds = heroes.map(hero => hero.heroId);
            createUserMineMutation.mutate({mineId: mineId, heroIds: heroIds});
        }
    }

    const myModalEl = document.getElementById('minesModal' + mineId);
    if (myModalEl) {
        myModalEl.addEventListener('hidden.bs.modal', function (event) {
            setChoosenHeroes([]);
        })
    }

    const handleChooseHero = (item) => {
        if (choosenHeroes.includes(item)) {
            setChoosenHeroes((prevVal) => {
                const newHeroes = [];
                for (const val of prevVal) {
                    if (val.id !== item.id) {
                        newHeroes.push(val);
                    }
                }
                return newHeroes;
            });
        }

        else {
            if (choosenHeroes.length === 4) {
                ToastSuccess(t("minesModalAdd.selected4"), true)
            }
            else {
                setChoosenHeroes((prevVal) => [...prevVal, item]);
            }
        }
    }

    return (
        <div className="modal fade" id={'minesModal' + mineId} tabIndex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true" style={{backdropFilter: 'blur(5px)'}}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-transparent border-0">
                    <div className='mines-modal-container mx-auto'>
                        <div className='d-flex flex-wrap justify-content-center mines-modal-img-container'>
                            {getHeroesIsLoading &&
                                <div style={{height: '100px'}}>
                                    <DataFetchLoader width={50} height={50} color={"#B28E4C"}/>
                                </div>
                            }
                            {!getHeroesIsLoading &&
                                heroes.map(item => {
                                    return (
                                        <div onClick={() => handleChooseHero(item)} className='hero-mine-wait-img position-relative'>
                                        <img id={`${mineId}-${item.hero.nameEn}-img`} src={`./img/heroes/${item.hero.pathImage}.png`} width={'55.5px'} height={'55.5px'}
                                        style={choosenHeroes.includes(item) ? {opacity: "50%"} : {opacity: "100%"}} />
                                        <div className='position-absolute w-100 top-0 h-100 d-flex justify-content-center align-items-center'
                                            style={choosenHeroes.includes(item) ? {visibility: "visible", zIndex: 2} : {visibility: "hidden"}} id={`${mineId}-${item.hero.nameEn}-completed`}>
                                            <svg width="26" height="20" viewBox="0 0 26 20" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 11.9527L7.4634 18.4936L24.3031 1" stroke="#B28E4C"
                                                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </div>
                                        <div className='mines-modal-add-reward position-absolute d-flex justify-content-center align-items-center text-white'>
                                            {numberFormat(item.hero.incomeCrystal)}
                                        </div>
                                    </div>
                                    )
                                })
                            }                           
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <div className='d-flex align-items-center'>
                                    <div className='hero-mines-gray-text' style={{fontSize: "16px"}}>{t("mines.received") + " "}</div>

                                    <div style={{marginLeft: "4px"}} className='text-white'>{choosenHeroes.length === 4 ? numberFormat(getMineRewardByHeroesByReward(choosenHeroes, reward)) : `${choosenHeroes.length}/4`}</div>
                                    
                                    {choosenHeroes.length === 4 &&
                                        <img src='./img/loot/crystal-icon.png' width={'7.89px'}/>
                                    }
                                </div>
                                <div className='hero-mines-gray-text' style={{fontSize: "10px", paddingRight: '10px'}}>
                                    {t("mines.modalText")}
                                </div>
                            </div>
                            <div id={`mines-modal-${mineId}`} className='position-relative mines-modal-text-container m-0' type='button'
                                 onClick={() => createOnClick(choosenHeroes)} >
                                <svg width="110" height="32" viewBox="0 0 110 32" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M103.045 16.1131L87.9291 31H19.3521L2 16.1131L19.3521 1.22369H87.9291L103.045 16.1131Z"
                                        stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10"/>
                                    <path
                                        d="M108.322 15.8894L93.206 30.7763H24.6289L7.27689 15.8894L24.6289 1H93.206L108.322 15.8894Z"
                                        stroke="#B28E4C" strokeMiterlimit="10"/>
                                </svg>
                                <div className='position-absolute w-100 top-0 bottom-0 my-auto d-flex justify-content-center align-items-center hero-mines-gray-text'
                                    style={{fontSize: "14px"}}>
                                    {!addIsLoading &&
                                        t("mines.add")
                                    }
                                    {addIsLoading &&
                                        <DataFetchLoader width={17} height={17} color={"#B28E4C"}
                                      />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}