//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'

export const userHeroBonusSlice = createSlice({
    name: "userHeroBonus",
    initialState: {
        EVENTOLDCOIN: 0,
    },
    reducers: {
        setUserHeroBonusEVENTOLDCOIN: (state, action) => {     
            state.EVENTOLDCOIN = action.payload   
        }
    },
})

export const { setUserHeroBonusEVENTOLDCOIN } = userHeroBonusSlice.actions

export default userHeroBonusSlice.reducer