//@ts-nocheck

import './ClanContainerMyClan.scss'
import ClanContainerMyClanProfile from './ClanContainerMyClanProfile/ClanContainerMyClanProfile'
import ClanContainerMyClanNoClan from './ClanContainerMyClanNoClan/ClanContainerMyClanNoClan'
import { useQuery } from 'react-query';
import { getUserById } from '../../../api/clan';
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader';
import DataFetchError from '../../DataFetchError/DataFetchError';
import { useTranslation } from 'react-i18next';

export default function ClanContainerMyClan() {

  const { t } = useTranslation()

  const { data, isLoading, isError, isSuccess, isFetching } = useQuery(
    ['clanUserById'],
    () => getUserById(),
    {
      keepPreviousData: true,
    }
  )

  return (
    <div className={'clan-container-myclan h-100 w-100 overflow-hidden'}>

      {(isSuccess && !isFetching) &&
        <>
          {data.clan !== null &&
            <ClanContainerMyClanProfile item={data.clan}/>
          }
          {data.clan === null &&
            <ClanContainerMyClanNoClan />
          }
        </>
      }
      {(isLoading && !isFetching) &&
        <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
      }
      {isFetching &&
        <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
      }
      {isError &&
        <DataFetchError text={t("questQuests.dataFetchError")} />
      }

    </div>
  )
}