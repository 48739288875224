//@ts-nocheck

import './ShopHeroes.scss'
import { useQuery } from "react-query";
import { get } from "../../api/shopHero";
import ShopHero from "./ShopHero/ShopHero";
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader';
import DataFetchError from '../DataFetchError/DataFetchError';
import { useTranslation } from 'react-i18next';
import ShopHeroStarsItem from './ShopHeroStarsItem/ShopHeroStarsItem';

export default function ShopHeroes() {
    const { t } = useTranslation()

    const { data, isLoading, isError, isSuccess } = useQuery(
        ['shopHero'],
        () => get(),
        {
            keepPreviousData: true,
        }
    );

    return (
        <>
            {isSuccess &&
                <div className='w-100 d-flex flex-wrap justify-content-between' style={{ columnGap: '2%' }}>
                    {(data.data.shopHero.length === 0 && data.data.shopHeroStars.length === 0) &&
                        <div className='text-white w-100 d-flex justify-content-center'>{t("shopHeroContainer.emptyStore")}</div>
                    }
                    {
                        data.data.shopHero.map(shopHero => {
                            return (<>
                                <ShopHero shopHero={shopHero} />
                            </>)
                        })
                    }
                    {
                        data.data.shopHeroStars.map(shopHeroStars => {
                            return (<>
                                <ShopHeroStarsItem shopHeroStars={shopHeroStars} />
                            </>)
                        })
                    }
                </div>
            }
            {isLoading &&
                <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
            }
            {isError &&
                <DataFetchError text={t("shopHeroContainer.dataFetchError")} />
            }
        </>
    )
}