import { useTranslation } from 'react-i18next'
import './ApiError.scss'
import ApiErrorIcon from './ApiErrorIcon/ApiErrorIcon'

export default function ApiError() {

  const { t } = useTranslation()

  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center api-error flex-column position-fixed" style={{ zIndex: 99999999999999 }}>
      <ApiErrorIcon />
      {t("apiError")}
    </div>
  )
}