//@ts-nocheck

import BattlesCanvasMonster from './BattlesCanvasMonster/BattlesCanvasMonster';
import './BattlesCanvas.scss'
import BigNumber from 'bignumber.js';
import { Canvas } from "@react-three/fiber";
import { setHitAnimation, setIdleAnimation, setDieAnimation } from '../../redux/userMonsterAnimation/userMonsterAnimation';
import { useDispatch, useSelector } from 'react-redux';
import { incrementUserUserOldCoin } from '../../redux/userOldCoinSlice/userOldCoinSlice';
import { decrementHealth, incrementLevel, setCurrent0, setFullHealth, setLevel1, setMaxHealth } from '../../redux/userMonsterHealth/userMonsterHealth';
import { setIsStart } from '../../redux/userBattlesStartSlice/userBattlesStartSlice';
import { useEffect, useState } from 'react';
import {numberFormat} from "../../utils/NumberFormat";
import {current} from "@reduxjs/toolkit";

export default function BattlesCanvas() {

    const currentHealth = useSelector((state) => state.userMonsterHealth.current);
    const maxHealth = useSelector((state) => state.userMonsterHealth.max);
    const level = useSelector((state) => state.userMonsterHealth.level);
    const damage = useSelector((state) => state.userHeroesDamage.damage);
    const userHeroBonusEVENTOLDCOIN = useSelector((state) => state.userHeroBonus.EVENTOLDCOIN)

    const dispatch = useDispatch()

    const [monsterDead, setMonsterDead] = useState(false)
    const [isCooldown, setIsCooldown] = useState(false)

    const [counter, setCounter] = useState(0)

    const [xcoord, setXcoord] = useState(0)
    const [ycoord, setYcoord] = useState(0)

    const setCoords = (e) => {
        setXcoord(e.clientX);
        setYcoord(e.clientY);
    }


    const battlesCanvasOnClick = () => {
        if(monsterDead) return
        if(isCooldown) return

        setIsCooldown(true)
        setTimeout(() => setIsCooldown(false), 100)

        setCounter(counter + 1);

        let damageDiv = document.createElement('div');
        damageDiv.className = 'damage position-fixed z-3';
        damageDiv.id = `damage${counter}`;
        damageDiv.innerHTML = `<p>-${numberFormat(damage)}</p>`;
        damageDiv.style.left = `${xcoord}px`;
        damageDiv.style.top = `${ycoord-50}px`;
        // damageDiv.onclick = () => {
        //     battlesCanvasOnClick()    
        // }
        document.body.appendChild(damageDiv);
        setTimeout(() => {
            const damageToRemove = document.getElementById(`damage${counter}`);
            document.body.removeChild(damageToRemove);
        }, 1400);


        if (level === 15) {
            dispatch(setIsStart(false))
            dispatch(setLevel1())
            const myModal = new bootstrap.Modal(document.getElementById('battlesRewardModal'), {
                keyboard: false
            })
            myModal.show()
        }

        if (BigInt(currentHealth) - BigInt(damage) <= BigInt("0")) {
            
                dispatch(setCurrent0())
                setMonsterDead(true)

                setTimeout(() => {
                    dispatch(setIsStart(false))
                    dispatch(incrementLevel())
                    dispatch(setMaxHealth())
                    dispatch(setFullHealth())
                    
                    dispatch(incrementUserUserOldCoin({level: level, userHeroBonusEVENTOLDCOIN: userHeroBonusEVENTOLDCOIN}))

                    setMonsterDead(false)
                }, 1050)

            return
        }
        
        dispatch(decrementHealth(damage))
    }

    useEffect(() => {
        if (currentHealth > BigInt("0")) {
            dispatch(setHitAnimation());

            setTimeout(() => {
                dispatch(setIdleAnimation())
            }, 10)
        }
        else {
            dispatch(setIdleAnimation());
            setTimeout(() => {
                dispatch(setDieAnimation())
            }, 50)
        }
    }, [currentHealth])

    return (
        <div onClick={battlesCanvasOnClick} onMouseDown={setCoords} className='h-100 position-absolute start-50 translate-middle-x' style={{bottom: '-23.5%'}}>
            <Canvas shadows camera={{ position: [-0.3, 2, 5] }}>
                <directionalLight
                    position={[1, 15, 0]}
                    castShadow
                    shadow-mapSize-width={1024}
                    shadow-mapSize-height={1024}
                    color={'white'}
                />
                <BattlesCanvasMonster />
                <mesh rotation-x={5.1} receiveShadow >
                    <planeGeometry args={[10, 10, 1, 1]} />
                    <shadowMaterial transparent opacity={0.7} />
                </mesh>
            </Canvas>
        </div>
    )
}
