import axios from "axios";
import { getInitDataRaw } from "../utils/telegram"
import store from "../redux/store";
import { setUserApiError } from "../redux/Error/userApiError/userApiErrorSlice";
import { setUserApiErrorHash } from "../redux/Error/userApiErrorHash/userApiErrorHashSlice";
const initDataRaw: string | undefined = getInitDataRaw()

export const getApiUrl = (): string | undefined => {
    if (process.env.NODE_ENV === 'production')
        return process.env.REACT_APP_API_URL_PRODUCTION;
    if (process.env.NODE_ENV === 'development')
        return process.env.REACT_APP_API_URL_DEVELOPMENT;
    return ""
}

export const getAxiosConfig = (): Object => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": getApiUrl(),
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS, PUT",
            "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
            "Authorization": `tma ${initDataRaw}`
        }
    }
}

axios.defaults.timeout = 300000
axios.interceptors.response.use(
    response => response,
    error => {
        
        if(error.response !== undefined && error.response.status === 403) {
            if(error.response.data.errorCode === "UserInvalidSession") {
                store.dispatch(setUserApiErrorHash(true))        
            }           
        } else {
            store.dispatch(setUserApiError(true)) 
        }
        
        return Promise.reject(error)
    }
);